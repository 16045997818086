import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { WhatWeDoInfo } from "./WhatWeDoInfo"

export const WhatWeDo = () => {
    return (
        <>
          <Header active="About"/>
            <HeroSection/>
            <WhatWeDoInfo/>
            <BrightFutureTogether/>
            <Footer/>
  
        </>
    )
}