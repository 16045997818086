import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const GlobalKisanMarket = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* -------------------------- */}

            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Global Kisan Market - ICT Model</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        FPCs (Farmers' Producer Companies) are agricultural cooperatives primarily composed of small and marginal
                        farmers. Presently, there are approximately 12,000 FPCs operating in the state of Maharashtra, established
                        through various initiatives of the Indian Government, state governments, NABARD, and other organizations. The
                        majority of these FPCs are in their initial stages, with memberships ranging from 100 to over 1,000 farmers.
                        Currently, they require not only technical guidance but also sufficient capital, infrastructure, and
                        technology, including market connections, to sustain their business operations.
                    </p>

                    <p>
                        GK MARKET is a robust ICT platform designed to serve FPOs (Farmers' Producer Organizations), FPCs, buyers, and
                        agricultural processors, offering comprehensive services such as farmer backward linkage, commodity
                        projection, farm traceability, commodity procurement, E-commerce functionality, and market forward linkage,
                        input management, and account management modules.
                    </p>


                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Comprehensive ICT Platform</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>End-to-End Services</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Market Linkages</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Technology Integration</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Support for Emerging FPCs</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Commodity Procurement</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/GK_Market_Brochure_Landscape_updates1.pdf')}
                        // href="http://new.cdatinfotech.com/doc/GLOBAL_KISAN_MARKET.pdf"
                         class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}