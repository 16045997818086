export const GovenmentSector = () => {
    return (
        <>
            <div className="row bgdotimg " id="Government_sector">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Government Sector</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Our track record in government projects speaks for itself. We have successfully partnered with
                        government agencies, providing them with robust and secure software solutions tailored to their
                        specific needs.
                        From streamlining administrative processes to enhancing citizen services, we are well-versed in the
                        intricacies of government operations.
                    </p>

                </div>
            </div>
        </>
    )
}

