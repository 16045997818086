export const InfoCustomizedSolution = () => {
    return (
        <>

            <div className="row bgdotimg " id="Customized_Solution">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Customized Solution</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        We understand that every organization is unique, and off-the-shelf solutions may not always meet your specific
                        needs. That's where our customized solutions come in.

                        We understand that every business has unique requirements. Our team develops customized solutions tailored to
                        meet your specific needs.
                    </p>
                    <p>
                        We work closely with you to understand your challenges and deliver software solutions that address your
                        specific pain points.
                    </p>

                </div>
            </div>
            {/* <!--end of row Customized Solution content--> */}

            <div className="row" id="Graphic_Design_info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Customized Solution Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Customized/Requirement.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Requirement Analysis</b></h4>

                    <p className="p-2 pb-lg-5 ">We start by thoroughly understanding your business processes, challenges, and goals. Our
                        team works closely with you to gather requirements, identify pain points, and determine the best approach to
                        address your specific needs.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Customized/Solution Design.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Solution Design and Development</b></h4>

                    <p className="p-2 pb-lg-4">Based on your requirements, we design and develop customized software solutions from
                        scratch. Our experienced team of developers utilizes cutting-edge technologies and industry best practices to
                        create robust, scalable, and user-friendly solutions tailored to your business processes.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Customized/Solution Design.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Customized/Integration.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Integration and Migration</b></h4>

                    <p className="p-2 pb-lg-5 ">If you have existing systems or databases, we ensure seamless integration with our
                        customized solutions. We also offer migration services to help you transition from legacy systems to modern,
                        customized solutions without disrupting your operations.
                    </p>
                </div>
            </div>

            <div className="row services_shadow lightblue mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Customized/User Training.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>User Training and Support</b></h4>

                    <p className="p-2 pb-lg-4">We provide comprehensive training to ensure a smooth transition and user adoption of our
                        customized solutions. Additionally, our support team is available to assist you with any questions, issues, or
                        enhancements that may arise after implementation.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Customized/User Training.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Customized/flexibility.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Scalability and Flexibility</b></h4>

                    <p className="p-2 pb-lg-5 ">Our customized solutions are designed to grow with your business. We create scalable and
                        flexible software solutions that can adapt to changing needs, accommodate future expansions, and integrate
                        additional features as your business evolves.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Customized/Upgrades.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Ongoing Maintenance and Upgrades</b></h4>

                    <p className="p-2 pb-lg-4">We offer ongoing maintenance services to ensure your customized solution operates
                        smoothly and remains up-to-date. Our team performs regular updates, bug fixes, and enhancements to keep your
                        software running efficiently and securely.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Customized/Upgrades.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>
        </>
    )
}