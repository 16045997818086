export const InfoManufacturingServicesIndustry =()=>{
    return(
        <>
               <div className="row bgdotimg " id="Manufacturing_&_Services_Industry">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">Manufacturing & Services Industry</h3>

                <div className="zoom-in-out-box2 mx-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-10 mx-auto py-3 text-center">
                <p>
                    At CDAT, we understand the pivotal role that technology plays in transforming the manufacturing and service industries. With our profound expertise and cutting-edge software solutions, we empower businesses in these sectors to achieve new heights of efficiency, productivity, and innovation. Our unwavering commitment to excellence is underpinned by the advantages and key features that define our professional and corporate approach.
                </p>

            </div>
        </div>  
        </>
    )
}