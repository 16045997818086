export const FinancialServicesInfo =()=>{
    return(
        <>

<div className="row bgdotimg " id="Financial_Services">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">Financial Services</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-10 mx-auto py-3 text-center">
                <p>
                    The financial services sector demands secure and reliable software solutions. With our in-depth knowledge of banking, insurance, and financial technologies, we deliver solutions that enable seamless transactions, risk management, fraud detection, and regulatory compliance.
                    Our solutions empower financial institutions to stay competitive in a rapidly evolving industry.
                </p>

            </div>
        </div>
        </>
    )
}