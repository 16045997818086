export const InfoMobileAppDevelopment = () => {
    return (
        <>

            <div className="row bgdotimg " id="Mobile_App_Development">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Mobile App Development</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        At CDAT Company, we are passionate about developing mobile applications that make a difference. We combine our
                        technical expertise with creative thinking to deliver mobile solutions that align with your business
                        objectives and drive user engagement.

                        Our team of skilled developers and designers work closely with you to bring your mobile app vision to life,
                        ensuring a seamless and user-friendly experience.
                    </p>
                    <p>
                        Our mobile development services focus on creating intuitive and feature-rich apps that provide exceptional
                        user experiences.

                        We specialize in developing mobile applications for iOS and Android platforms.
                    </p>

                </div>
            </div>
            {/* <!--end of row Mobile App Development--> */}

            <div className="row" id="Website_Development_info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Mobile App Development Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/App/Native Mobile App Development.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Native Mobile App Development</b></h4>

                    <p className="p-2 pb-lg-5 ">We develop native mobile applications for iOS and Android platforms, leveraging the full
                        potential of each operating system. Our apps are built from scratch, ensuring optimal performance,
                        responsiveness, and integration with device features.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/App/cross-platform.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Cross-Platform App Development</b></h4>

                    <p className="p-2 pb-lg-4">Save time and resources with our cross-platform app development services. We use
                        frameworks like React Native and Flutter to build mobile apps that run smoothly on multiple platforms,
                        ensuring a consistent user experience across devices.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/App/cross-platform.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/App/User-Centric Design.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>User-Centric Design</b></h4>

                    <p className="p-2 pb-lg-5 ">We prioritize user experience and design aesthetics in every mobile app we develop. Our
                        designers create intuitive interfaces, seamless navigation, and visually engaging designs that captivate users
                        and keep them coming back for more.
                    </p>
                </div>
            </div>

            <div className="row services_shadow lightblue mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/App/Custom Functionality & Features.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Custom Functionality & Features</b></h4>

                    <p className="p-2 pb-lg-4">Our mobile apps are tailored to meet your unique business requirements. Whether you need
                        e-commerce integration, location-based services, social media connectivity, or any other custom functionality,
                        we have the expertise to deliver.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/App/Custom Functionality & Features.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/App/App Testing & Quality Assurance.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>App Testing & Quality Assurance</b></h4>

                    <p className="p-2 pb-lg-5 ">We conduct rigorous testing throughout the development process to ensure that your
                        mobile app meets the highest quality standards. Our QA specialists perform comprehensive testing to identify
                        and resolve any bugs or issues, guaranteeing a smooth user experience.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/App/App Store Deployment & Support.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>App Store Deployment & Support</b></h4>

                    <p className="p-2 pb-lg-4">We assist you in deploying your mobile app to the app stores, ensuring it reaches your
                        target audience seamlessly. Additionally, we provide ongoing support and maintenance to ensure that your app
                        remains up-to-date and compatible with new OS versions and devices.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/App/App Store Deployment & Support.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>


        </>
    )
}