export const Mission = () => {
    return (
        <>
          
          <div className="row" id="Mission">

<div className="col-12 col-md-6 col-lg-5 py-5 text-center mx-auto">
    <img src="Assets/Images/whyus.png" alt="" style={{width: '80%', height: '50vh'}} className="ds"/>
</div>

<div className="col-12 col-md-10 col-lg-6 py-5 pe-lg-5 mx-auto  alignment">
    <h3 className="fw-bold colorcharchol text-center">Mission</h3>

    <div className="zoom-in-out-box2 mx-auto">
        <hr/>
    </div>
    <p className="py-5 px-2">Our mission at CDAT is to provide exceptional software solutions that empower businesses to thrive in a rapidly evolving digital landscape. With a focus on innovation, reliability, and customer satisfaction, we aim to deliver cutting-edge technology products and services tailored to meet the unique needs of our clients. Through our expertise and commitment to excellence, we strive to be the preferred partner for organizations seeking to optimize their operations, achieve growth, and gain a competitive edge. By driving digital transformation and enabling businesses to harness the power of technology, we aspire to contribute to their long-term success and make a positive impact in the world of software solutions.</p>
</div>


</div>
        </>
    )
}