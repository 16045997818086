export const AgricultureKeyFeatures =()=>{
    return(
        <>
        <div className="row py-5 px-3 px-lg-3" id="key">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr />
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Farm Management Software</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Precision Irrigation Systems</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />

                    <p> <b>Smart Pest and Disease Management</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Market Intelligence</b></p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p><b>Farm-to-Consumer Communication</b></p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p><b>Climate-Resilient Strategies</b></p>
                </div>
            </div>
        </div>
    </>
    )
}