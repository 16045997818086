import { Footer } from "./Footer"
import { Header } from "./Header"
import { HeroSection } from "./HeroSection"

export const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <HeroSection />

            {/* -------------------- */}

            <div className="row" id="privacy data">
                <div className="col-12 text-center">
                    <h3 className="pt-5 text-black blue_text fw-bold">Privacy Policy</h3>
                    <div className="zoom-in-out-box2 mb-5 mx-auto  text-center">
                        <hr/>
                    </div>

                </div>
                <div className="col-10 mx-auto text-center">
                    <p>
                        At <b>CDAT</b>, we are committed to protecting the privacy and personal information of our website visitors
                        and users. This Privacy Policy explains how we collect, use, store, and protect your data when you interact
                        with our website and software applications.
                    </p>
                </div>

                <div className="col-10 pb-4 mx-auto text-start">
                    <h5 className=" my-4 fw-bold">Information We Collect</h5>
                    <p><b>Personal Information:</b></p>
                    <p>When you use our website or software, we may collect personal information such as your name, email address,
                        contact information, and any other information you provide voluntarily.</p>

                    <p className="mt-4"><b>Personal Information:</b></p>
                    <p>We may gather non-personal information about your interactions with our website and software, including IP
                        addresses, device information, browser type, and usage patterns. This information is used to improve our
                        services and enhance user experience.</p>

                    <h5 className=" my-4 fw-bold">Use of Collected Information</h5>
                    <p>We may use the information we collect to provide and improve our services, personalize user experience,
                        respond to inquiries, and communicate with you about updates, offers, and important notices. We may analyze
                        usage data to understand trends, monitor the effectiveness of our marketing campaigns, and improve our
                        products and services.</p>

                    <h5 className=" my-4 fw-bold">Data Sharing and Third Parties</h5>
                    <p> We do not sell, rent, or trade your personal information to third parties without your consent unless
                        required by law. We may share your information with trusted service providers who assist us in operating our
                        business, providing services to you, or conducting our operations. These providers are contractually obligated
                        to maintain the confidentiality and security of your data.</p>

                    <h5 className=" my-4 fw-bold">Data Security</h5>
                    <p> We implement reasonable security measures to protect your personal information from unauthorized access,
                        disclosure, or misuse. However, no data transmission or storage method can be guaranteed to be 100% secure.
                    </p>

                    <h5 className=" my-4 fw-bold">Data Retention</h5>
                    <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was
                        collected, as required by law, or as needed to protect our legal rights, resolve disputes, or enforce our
                        agreements.</p>

                    <h5 className=" my-4 fw-bold">Cookies and Tracking Technologies</h5>
                    <p>We may use cookies and similar tracking technologies to enhance user experience, analyze usage patterns, and
                        provide personalized content. You have the option to disable cookies through your browser settings, although
                        this may affect certain features of our website or software.</p>

                    <h5 className=" my-4 fw-bold">Your Rights</h5>
                    <p>You have the right to access, update, correct, or delete your personal information held by us. If you have
                        any requests or concerns regarding your data, please contact us using the provided contact information.</p>

                    <h5 className=" my-4 fw-bold">Changes to the Privacy Policy</h5>
                    <p>We reserve the right to update this Privacy Policy periodically. Any changes will be effective upon posting
                        the revised policy on our website. We encourage you to review this policy regularly.</p>

                    <p>If you have any questions or require further information about our privacy practices, please contact us at <a
                        href="tel:9404953482" className="colorcharchol">9404953482</a> .</p>
                </div>
            </div>



            <Footer />
        </>
    )
}