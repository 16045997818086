import { useNavigate } from "react-router-dom"

export const BrightFutureTogether =()=>{
  const navigate = useNavigate()
    return(
        <>
             <div className="row" id="Brighter_Future charchole">
      <div className="col-12 charchole py-5">
        <h2 className="text-center white">
          <b>
            Let's Create A Brighter Future Together
          </b>
        </h2>
        <p className="white  text-center py-3">
          Let us help you give you a roadmap to achieve industry benchmarks.
        </p>

        <div className="py-4 text-center">
          <a href="javascript:void(0)"
         onClick={() =>navigate('/ContactPage?ScreenName=Contact')}
            className="shrink-border text-decoration-none">Connect</a>

        </div>
      </div>
    </div>
        </>
    )
}