import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoProductDevelopment } from "./InfoProductDevelopment"


export const ProductDevelopment =()=>{
    return(
        <>
             <Header active="Services"/>
            <HeroSection/>
            <InfoProductDevelopment/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}