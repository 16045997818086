import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { AdvantagesOfMSI } from "./AdvantagesOfMSI"
import { InfoManufacturingServicesIndustry } from "./InfoManufacturingServicesIndustry"
import { KeyFeaturesManufacturingServicesIndustry } from "./KeyFeaturesManufacturingServicesIndustry"

export const ManufacturingAndServicesIndustry =() =>{
    return(
        <>
            <Header/>
            <HeroSection/>
            <InfoManufacturingServicesIndustry/>
            <KeyFeaturesManufacturingServicesIndustry/>
            <AdvantagesOfMSI/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}