import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { BrightFutureTogether } from "./BrightFutureTogether"
import { Ourblogs } from "./OurBlogs"

export const Blog =()=>{
    return(
       <>
         <Header  active="Blog"/>
        <HeroSection/>
        <Ourblogs/>
        <BrightFutureTogether/>
        <Footer/>
       </>
    )
}