import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { HealthcareAdvantages } from "./HealthcareAdvantages"
import { HealthcareInfo } from "./HealthcareInfo"
import { HealthcareKeyFeatures } from "./HealthcareKeyFeatures"

export const Healthcare = ()=>{
    return(
        <>  <Header active="Expertise"/>
            <HeroSection/>
            <HealthcareInfo/>
            <HealthcareKeyFeatures/>
            <HealthcareAdvantages/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}