export const BiServices=()=>{
    return(
        <>
             <div class="row" id="BI solutions info">
            <div class="col-12 col-md-10 mx-auto py-4">
                <h3 class="fw-bold colorcharchol text-center">Our Business Intelligence Solutions Services</h3>

                <div class="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
        </div>

        {/* --------------------------------------- */}
        
        <div className="row services_shadow orange mx-auto my-4">
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                <img src="Assets/Images/bi-solution/stopwatch.png" alt="img not found" srcset="" style={{width:'50%'}} className="ds"/>
            </div>
    
            <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                <h4 className="pt-3 charcholecolor px-2 "><b>Gain Real-Time Insights</b></h4>
    
                <p className="p-2 pb-lg-5 ">Access real-time data and analytics to make informed decisions on the fly. Our solutions provide you with up-to-date information, empowering you to respond quickly to changing market conditions and make strategic decisions based on accurate, timely insights.
                </p>
            </div>
        </div>

        <div className="row services_shadow green mx-auto my-4 sky">
            <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                <img src="Assets/Images/bi-solution/Visualize Data Effectively.png" alt="img not found" srcset="" style={{width:'50%'}} className="ds"/>
            </div>
    
            <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                <h4 className="pt-3  px-2"><b>Visualize Data Effectively</b></h4>
    
                <p className="p-2 pb-lg-4">Our intuitive visualizations make complex data easy to understand. We create interactive
                    dashboards, charts, and graphs that allow you to explore and present data in a visually appealing
                    and digestible format. This enables you to identify patterns, trends, and outliers at a glance.
                </p>
            </div>
    
            <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                <img src="Assets/Images/bi-solution/Visualize Data Effectively.png" alt="" srcset="" style={{width:'50%'}} className="ds"/>
            </div>
        </div>

        <div className="row services_shadow pink mx-auto my-4">
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                <img src="Assets/Images/bi-solution/Drive Performance.png" alt="img not found" srcset="" style={{width:'50%'}}/>
            </div>
    
            <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                <h4 className="pt-3 charcholecolor px-2 "><b>Drive Performance and Efficiency</b></h4>
    
                <p className="p-2 pb-lg-5 ">By leveraging our BI solutions, you can optimize your business performance and drive operational efficiency. Our tools help you identify bottlenecks, optimize processes, and uncover areas for improvement, leading to enhanced productivity and cost savings.
                </p>
            </div>
        </div>

        <div className="row services_shadow lightblue mx-auto my-4 sky">
            <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                <img src="Assets/Images/bi-solution/Make Informed Decisions.png" alt="img not found" srcset="" style={{width:'50%'}} />
            </div>
    
            <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                <h4 className="pt-3  px-2"><b>Make Informed Decisions</b></h4>
    
                <p className="p-2 pb-lg-4">With our BI solutions, you can make data-driven decisions with confidence. Access accurate, reliable insights that provide a comprehensive view of your business, enabling you to identify opportunities, mitigate risks, and make informed choices that propel your success.
                </p>
            </div>
    
            <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                <img src="Assets/Images/bi-solution/Make Informed Decisions.png" alt="" srcset="" style={{width:'50%'}}/>
            </div>
        </div>

        <div className="row services_shadow orange mx-auto my-4">
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                <img src="Assets/Images/bi-solution/Foster a Data-Driven Culture.png" alt="img not found" srcset="" style={{width:'50%'}}/>
            </div>
    
            <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                <h4 className="pt-3 charcholecolor px-2 "><b>Foster a Data-Driven Culture</b></h4>
    
                <p className="p-2 pb-lg-5 ">Our BI solutions foster a data-driven culture within your organization. Empower your teams with self-service analytics, allowing them to explore data, generate reports, and gain insights independently. This promotes collaboration, innovation, and a shared understanding of your business objectives.
                </p>
            </div>
        </div>
       
        </>
    )
}