export const CoreValue = () => {
  return (
    <>
      <div className="row px-3 px-lg-4 " id="core">
        <div className="col-10 col-md-10 m-auto py-5 text-center">
          <h3 className="fw-bold"> CORE VALUES</h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>
          {/* <!-- <h4 className="fw-bold"> Trust Us</h4> --> */}
          <p className=" pt-4 pb-3 text-center">At CDAT, these core values shape our identity, differentiate us in the
            industry, and drive
            us to deliver innovative, sustainable, and impactful solutions for our clients.
          </p>
        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Synergy.png" alt="image not found" />
              </div>
              <div className="solu_title">
                <h3 className="blackc">Synergy</h3>
              </div>
              <div className="solu_description">
                <p>
                  We believe in the power of synergy, leveraging the collective expertise of our team and clients to
                  achieve remarkable outcomes that surpass individual efforts.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Empathy.png" alt="" />
              </div>
              <div className="solu_title">
                <h3>Empathy</h3>
              </div>
              <div className="solu_description">
                <p>
                  We prioritize empathy, understanding the unique challenges and aspirations of our clients, and tailoring
                  our solutions to their specific needs.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Continuous Growth.png" alt="" />
              </div>
              <div className="solu_title">
                <h3>Continuous Growth</h3>
              </div>
              <div className="solu_description">
                <p>
                  We foster a culture of continuous growth, encouraging our team members to expand their knowledge and
                  skills, and staying ahead of industry trends to deliver cutting-edge solutions.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>

            </div>
          </div>

        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Proactive Approach.png" alt="" />
              </div>
              <div className="solu_title">
                <h3>Proactive Approach</h3>
              </div>
              <div className="solu_description">
                <p>
                  We adopt a proactive approach, anticipating client needs and taking proactive measures to address
                  challenges and seize opportunities before they arise.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>

            </div>
          </div>

        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Sustainable Impact.png" alt="" />
              </div>
              <div className="solu_title">
                <h3>Sustainable Impact</h3>
              </div>
              <div className="solu_description">
                <p>
                  We are committed to creating sustainable impact through our solutions, considering the long-term
                  benefits for our clients, society, and the environment.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>

            </div>
          </div>

        </div>

        <div className="col-12 col-md-6 col-lg-4 mt-2 mb-4">
          <div className="solution_cards_box">
            <div className="solution_card">
              <div className="hover_color_bubble"></div>
              <div className="so_top_icon" height="50" width="40">
                <img src="Assets/Images/Integrity.png" alt="" />
              </div>
              <div className="solu_title">
                <h3>Integrity</h3>
              </div>
              <div className="solu_description">
                <p>
                  We conduct ourselves with the utmost integrity, adhering to ethical standards and maintaining a strong
                  sense of honesty and fairness.
                </p>
                {/* <!-- <button type="button" className="read_more_btn">Read More</button> --> */}
              </div>

            </div>
          </div>

        </div>



      </div>
    </>
  )
}