export const InfoWebsiteDevelopment =()=>{
    return(
        <>


    <div className="row bgdotimg " id="Website_Development">
      <div className="col-12 col-md-10 mx-auto py-4">
        <h3 className="fw-bold colorcharchol text-center">Website Development</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
      <div className="col-12 col-md-10 mx-auto py-3 text-center">
        <p className=" alignment">
          At CDAT Company, we believe in the power of a well-crafted website to elevate your online presence and drive
          business growth. Our web development services are tailored to your unique requirements, delivering websites
          that not only look impressive but also perform exceptionally.

          At CDAT Company, we are experts in web development, helping businesses establish a strong online presence and
          captivate their target audience. Our web development services combine creativity, technical expertise, and
          user-centric design to deliver websites that leave a lasting impression.
        </p>
        <p className=" alignment">
          We offer professional web development services to create highly functional and visually appealing websites.

          Our team of experienced developers ensures responsive design, seamless user experience, and optimized
          performance.
        </p>

      </div>
    </div>
    {/* <!--end of row Website Development--> */}

    <div className="row" id="Website_Development_info">
      <div className="col-12 col-md-10 mx-auto py-4">
        <h3 className="fw-bold colorcharchol text-center">Our Website Development Services</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
    </div>

    <div className="row services_shadow orange mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/web/Custom Website Design.png" alt="img not found" srcset=""  style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>Custom Website Design</b></h4>

        <p className="p-2 pb-lg-5 ">We create unique and visually stunning websites tailored to your brand identity. Our
          team of designers collaborates closely with you to understand your vision and craft a website that reflects
          your values, engages your audience, and drives conversions.
        </p>
      </div>
    </div>

    <div className="row services_shadow green mx-auto my-4 sky">
      <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
        <img src="Assets/Images/web/Responsive Development.png" alt="img not found" srcset=""  style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
        <h4 className="pt-3  px-2"><b>Responsive Development</b></h4>

        <p className="p-2 pb-lg-4">Our websites are built to adapt seamlessly to different screen sizes and devices. With
          mobile responsiveness at the forefront, we ensure that your website looks and functions flawlessly across
          smartphones, tablets, and desktops, maximizing user engagement and satisfaction.
        </p>
      </div>

      <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
        <img src="Assets/Images/web/Responsive Development.png" alt="" srcset=""  style={{width: '50%'}}/>
      </div>
    </div>

    <div className="row services_shadow pink mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/web/User Experience Optimization.png" alt="img not found" srcset=""  style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>User Experience Optimization</b></h4>

        <p className="p-2 pb-lg-5 ">We prioritize user experience in every aspect of our web development process. Through
          intuitive navigation, well-structured layouts & attention to detail, we create websites that offer a seamless
          & enjoyable browsing experience, resulting in higher customer retention & satisfaction.
        </p>
      </div>
    </div>

    <div className="row services_shadow lightblue mx-auto my-4 sky">
      <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
        <img src="Assets/Images/web/Content Management Systems.png" alt="img not found" srcset=""  style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
        <h4 className="pt-3  px-2"><b>Content Management Systems</b></h4>

        <p className="p-2 pb-lg-4">We empower you to manage and update your website effortlessly with user-friendly content
          management systems (CMS). Whether you prefer WordPress, Drupal, or other CMS platforms, we integrate robust
          systems that give you full control over your website's content and functionality.
        </p>
      </div>

      <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
        <img src="Assets/Images/web/Content Management Systems.png" alt="" srcset=""  style={{width: '50%'}}/>
      </div>
    </div>

    <div className="row services_shadow orange mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/web/E-Commerce Solutions.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>E-Commerce Solutions</b></h4>

        <p className="p-2 pb-lg-5 ">If you're looking to sell products or services online, our e-commerce solutions are
          designed to drive conversions & maximize revenue. We develop secure & scalable e-commerce platforms with
          intuitive shopping carts, payment gateways, and inventory management systems.
        </p>
      </div>
    </div>

    <div className="row services_shadow green mx-auto my-4 sky">
      <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
        <img src="Assets/Images/web/Performance and Security.png" alt="img not found" srcset=""  style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
        <h4 className="pt-3  px-2"><b>Performance and Security</b></h4>

        <p className="p-2 pb-lg-4">We prioritize website performance and security to provide a seamless and secure browsing
          experience for your visitors. Our development practices follow industry standards and best practices, ensuring
          optimal website speed, reliability, and protection against cyber threats.
        </p>
      </div>

      <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
        <img src="Assets/Images/web/Performance and Security.png" alt="" srcset=""  style={{width: '50%'}}/>
      </div>
    </div>


        </>
    )
    }