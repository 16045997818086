export const MapWithConactInfo = () => {
    return (
        <>
            <div class="row charchole" id="contact">

                <div class="col-12 col-md-12 m-auto py-3 text-center charchole">
                    <h3 class="fw-bold white">CONNECT</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-10 text-center py-3 charchole text-center mx-auto white">
                    <p>Don't miss out on the opportunity to revolutionize your business operations and gain a competitive edge in
                        the market. Take the next step by reaching out to us for more information or to schedule a consultation.</p>
                </div>

                <div class="col-12">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.520835968536!2d73.82188887436637!3d18.460052671021643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc295e613b54b99%3A0x466d7a51d1b0faef!2scenter%20for%20development%20advance%20technologies!5e0!3m2!1sen!2sin!4v1688378218568!5m2!1sen!2sin"
                        class="map_cdat" style={{border:'0', height: '60vh', width:'100%'}} allowfullscreen=" " loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="col-12 col-md-4 text-center contact-shadow py-3 charchole white" id="cdat_map">
                    <h2 class="silver"><i class="fa-solid fa-location-dot"></i></h2>
                    <h4><b>OFFICE</b></h4>
                    <address class="">
                        <a href="https://www.google.com/maps/dir/CDAT+PUNE,+Navale+Bridge,+Kudale+Baug,+Vadgaon+Budruk,+Pune,+Maharashtra/cdat/@18.4601246,73.7832637,13z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3bc295e613b54b99:0x466d7a51d1b0faef!2m2!1d73.8244638!2d18.4600476!1m5!1m1!1s0x3bc295e613b54b99:0x466d7a51d1b0faef!2m2!1d73.8244638!2d18.4600476?entry=ttu" target="_blank" class="text-decoration-none white a-hover">
                            103-104, A2,
                            Omkar Nandan, Navale Bridge, Opp.Navale IT Park, Vadgaon Bk. Pune 411041 Maharashtra,
                            India.</a>

                    </address>
                </div>

                <div class="col-12 col-md-4 text-center contact-shadow py-3 charchole white">
                    <h2 class="silver"><i class="fa-solid fa-phone"></i></h2>
                    <h4><b>PHONE</b></h4>
                    <a href="tel:8862082200" class=" text-decoration-none white a-hover">+91 8862082200</a> <br />
                    <a href="tel:9172650917" class=" text-decoration-none white a-hover">+91 9172650917</a>
                </div>

                <div class="col-12 col-md-4 text-center contact-shadow py-3 charchole white">
                    <h2 class="silver"><i class="fa-solid fa-envelope"></i></h2>
                    <h4><b>EMAIL</b></h4>
                    <a href="mailto:info@cdat.co.in" class=" text-decoration-none white a-hover">info@cdat.co.in</a> <br />
                    <a href="mailto:sales@cdat.co.in" class=" text-decoration-none white a-hover">sales@cdat.co.in</a>
                </div>
            </div>
        </>
    )
}