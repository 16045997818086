import { BrightFutureTogether } from "../BlogPage/BrightFutureTogether"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { Clients } from "./Clients"

export const OurClients =()=>{
    return(
    <>
          <Header active="Clients"/>
            <HeroSection />
            <Clients/>
            <BrightFutureTogether/>
            <Footer />
    </>
    )
}