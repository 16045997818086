import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoWebsiteDevelopment } from "./InfoWebsiteDevelopment"

export const WebsiteDevelopment =()=>{
    return(
        <>
        <Header active="Services"/>
        <HeroSection/>
        <InfoWebsiteDevelopment/>
        <BrightFutureTogether/>
        <Footer/>

        </>
    )
}