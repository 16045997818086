export const HealthcareInfo = ()=>{
    return(
        <>
            <div className="row bgdotimg " id="HEALTHCARE">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">Healthcare</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-10 mx-auto py-3 text-center">
                <p>
                    We understand the criticality of software solutions in the healthcare and life sciences industry.

                    expertise extends to developing HIPAA-compliant systems, electronic medical records (EMR) management, telemedicine platforms, and more.
                    
                    We enable healthcare organizations to deliver superior patient care while ensuring data privacy and compliance.
                </p>

            </div>
        </div>
        </>
    )
}