export const Advantages = () => {
    return (
        <>

            <div className="row charchole py-4 mb-5 px-lg-4" id="government_content">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold white text-center">ADVANTAGES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border1 text-center px-2">
                        <img src="Assets/Images/Governance/track.png" alt="" style={{ width: '120px' }} className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3"> Proven Track Record</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                Our content highlights a successful track record in government projects. This demonstrates our expertise and ability to deliver results in the government sector.
                                <span className="read-more-target ps-2">
                                    we  accomplishments, instill
                                    confidence in readers that we have the experience and capabilities to meet their
                                    specific needs.
                                </span>
                            </p>
                        </div>
                        <label for="read-more" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2">
                    <div className=" border border2 text-center px-2">
                        <img src="Assets/Images/Governance/Streamlined Administrative Processes.png" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Streamlined Administrative Processes</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                Our content emphasizes how our solutions are designed to streamline administrative processes
                                within
                                government agencies. By automating tasks, reducing paperwork, and improving
                                <span className="read-more-target ps-2">
                                    efficiency, we help
                                    government organizations operate more effectively and allocate resources efficiently.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border3 text-center px-2">
                        <img src="Assets/Images/Governance/solution.png" alt="" style={{ width: '120px' }} className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Tailored Solutions</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                We emphasize our capability to provide robust and secure software solutions customized to the unique requirements of government agencies This advantage assures readers that we
                                <span className="read-more-target ps-2">
                                    understand the complexities of government operations and can
                                    design solutions that align with their specific workflows and
                                    compliance standards.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border4 text-center px-2">
                        <img src="Assets/Images/Governance/Enhanced Citizen Services.png" alt="" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Enhanced Citizen Services</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                We highlight how our software solutions contribute to enhancing citizen services. By leveraging
                                technology, we enable government agencies to provide seamless & user-friendly experiences
                                <span className="read-more-target ps-2">
                                    to citizens This includes features such as online service portals, digital communication
                                    channels, &
                                    real-time information access, ensuring citizens receive prompt & convenient services.
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border5 text-center px-2">
                        <img src="Assets/Images/Governance/depth Knowledge.png"
                            alt="" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">In-depth Knowledge of Government Operations</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                We emphasize our familiarity with the intricacies of government operations. This advantage positions us as experts who understand the unique
                                <span className="read-more-target ps-2">
                                    challenges, regulations, and compliance requirements faced by government agencies. Our knowledge helps us design solutions that align with their goals and effectively address their pain points.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border6 text-center px-2">
                        <img src="Assets/Images/Governance/Security and Data Protection.png"
                            alt="" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Security and Data Protection</h5>
                
                        
                        <input type="checkbox" hidden class="read-more-state" id="read-more6" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content highlights our commitment to providing robust security measures and data protection
                            in
                            our software solutions. Government agencies deal with sensitive information, and ensuring the
                                <span className="read-more-target ps-2">
                                confidentiality, integrity, and availability of data is paramount. We communicate our
                                expertise in
                                implementing stringent security protocols to safeguard critical data from unauthorized
                                access or
                                breaches.
                                </span>
                            </p>
                        </div>
                        <label for="read-more6" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

            </div>
        </>
    )
}