export const WhatWeDoInfo =()=>{
    return(
        <>
                   <div className="row" id="WHAT_WE_DOinfo">
            <div className="col-12 col-md-10 text-center m-auto py-5 colorcharchol">
                <h2>
                    <b>
                        "Unlock Your Digital Potential with our Professional Web Design, Development, and App Design
                        Services"
                    </b>
                </h2>
            </div>


            <div className="col-12 px-5 bgdotimg  alignment">
                <p>
                    Welcome to our website! We are a team of skilled professionals specializing in web designing, web
                    development, graphic design, and e-commerce development. Our primary goal is to provide you with
                    professional and attractive digital solutions that cater to your specific needs. Whether you are an
                    individual, a small business, or a large enterprise, we have the expertise to deliver outstanding
                    results.
                </p>
                <p>
                    At our company, we pride ourselves on delivering high-quality solutions that meet and exceed our
                    clients' expectations. Our team is committed to understanding your unique requirements, providing
                    personalized attention, and ensuring that the end result aligns perfectly with your vision. We
                    believe in transparent communication, timely project delivery, and providing exceptional customer
                    support throughout the process
                </p>
                <p>
                    Get in touch with us today to discuss your project requirements. Together, we can create a powerful
                    online presence that sets you apart from the competition and drives your success in the digital
                    realm.
                </p>


            </div>
        </div>

        <div className="row p-5 " id="partners">




<div className="col-10 col-md-5 col-lg-3 m-auto mt-4 " data-aos="fade-up">
    <div className="card1 card-block  text-white px-5 pt-5 pb-2">
       <img src="Assets/Images/Web Development.png" alt="img not found" className="drops m-auto"
            style={{width: '100% ', borderRadius: '10%'}}/>
        <p className=" text-center py-4 colorcharchol">Web Design & Development</p>
    </div>
</div>

<div className="col-10 col-md-5 col-lg-3 m-auto mt-4 " data-aos="fade-up">
    <div className="card1 card-block  px-5 pt-5 pb-2">
        <img src="Assets/Images/customization.png" alt="img not found" className="drops m-auto"
           style={{width: '100% ', borderRadius: '10%'}}/>
       <p className=" text-center py-4 colorcharchol">Customized <br/> Solutions</p>
    </div>
</div>

<div className="col-10 col-md-5 col-lg-3 m-auto mt-4 " data-aos="fade-up">
    <div className="card1 card-block  px-5 pt-5 pb-2">
        <img src="Assets/Images/mobile-app.png" alt="img not found" className="drops m-auto"
          style={{width: '100% ', borderRadius: '10%'}}/>
        <p className=" text-center py-4 colorcharchol">Mobile App Design & Development</p>
    </div>
</div>

<div className="col-10 col-md-5 col-lg-3 m-auto mt-4 " data-aos="fade-up">
    <div className="card1 card-block  px-5 pt-5 pb-2">
        <img src="Assets/Images/ecommerce.png" alt="img not found" className="drops m-auto"
          style={{width: '100% ', borderRadius: '10%'}}/>
       <p className=" text-center py-4 colorcharchol">E-Commerce Development</p>
    </div>
</div>




</div>
        </>
    )
}