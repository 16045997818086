export const SupportNumber = () => {
  return (
    <div className="row  py-5" id="contactinfo">
      <div className="col-10 col-md-4 col-lg-3 mx-auto mt-4 ">
        <div className="card1 card-block  text-white text-center py-3">
          <img src="Assets/Images/contact-img/price-tag.png" alt="img not found" className="drops m-auto"
            style={{ width: '30%', borderRadius: '10%' }} />
          <h4 className=" text-center colorcharchol py-3"><b>SALES</b></h4>
          <span><a href="tel:9370382816" className="colorcharchol  a1-hover">+91 93703 82816</a></span> <br />
          <span><a href="mailto:sales@cdat.co.in" className="colorcharchol a1-hover">sales@cdat.co.in</a></span>
        </div>
      </div>

      <div className="col-10 col-md-4 col-lg-3 mx-auto mt-4 ">
        <div className="card1 card-block  text-white text-center py-3">
          <img src="Assets/Images/contact-img/tech-support.png" alt="img not found" className="drops m-auto"
            style={{ width: '30%', borderRadius: '10%' }} />
          <h4 className=" text-center colorcharchol py-3"><b>SUPPORT</b></h4>

          <span><a href="tel:9172650917" className="colorcharchol a1-hover">91726 50917</a></span>
          <span className="text-dark">/</span>
          <span><a href="tel:8862082200" className="colorcharchol a1-hover">88620 82200</a></span>
          <br />
          <span><a href="mailto:support@cdat.co.in" className="colorcharchol a1-hover">support@cdat.co.in</a></span>
        </div>
      </div>


      <div className="col-10 col-md-4 col-lg-3 mx-auto mt-4 ">
        <div className="card1 card-block  text-white text-center py-3">
          <img src="Assets/Images/contact-img/admin.png" alt="img not found" className="drops m-auto"
            style={{ width: '30%', borderRadius: '10%' }} />
          <h4 className=" text-center colorcharchol py-3"><b>ADMIN</b></h4>
          <span><a href="tel:9834340716" className="colorcharchol a1-hover">98343 40716</a></span> <br />
          <span><a href="mailto:admin@cdat.co.in" className="colorcharchol a1-hover">admin@cdat.co.in</a></span>
        </div>
      </div>

    </div>
  )
}