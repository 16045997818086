import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoMobileAppDevelopment } from "./InfoMobileAppDevelopment"

export  const MobileAppDevelopment =()=>{
    return(
        <>
             <Header active="Services"/>
            <HeroSection/>
            <InfoMobileAppDevelopment/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}