export const FinancialServicesKeyFeatures =()=>{
    return(
        <>
        <div class="row py-5 px-3 px-lg-4" id="key">
            <div class="col-12 col-md-10 mx-auto py-4">
                <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                <div class="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p> <b>Secure and Reliable Solutions</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p> <b>Industry-specific Expertise</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p> <b>Seamless Transactions</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p> <b>Risk Management</b></p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p><b>Fraud Detection</b></p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{width: '40px'}}/>
                    <p><b>Regulatory Compliance</b></p>
                </div>
            </div>
        </div>
        </>
    )
}