import CountUp from 'react-countup';
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollTrigger from 'react-scroll-trigger';

export const CounterUp = () => {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    AOS.init();
  }, [])


  return (
    <ScrollTrigger onEnter={() => setFocus(true)} onExit={() => setFocus(false)}>
      <div className="row pt-5 partnerR charchole " data-aos="fade-top" id="partners">

        <div className="col-12 col-md-5  col-lg-3 mx-auto py-2 pt-4 charchole">
          <div className="c text-center border-0 charchole">
            <img src="Assets/Images/gif/Professionals.gif" className="card-img-top partnersclients m-auto" alt="image not found" />
            <div className="card-body text-center white">
              <h2 className="card-title cardT  fw-bold pt-4" >
                {focus && <CountUp end={25} duration={5.75} />}

                +</h2>
              <p className="card-text text-center">Professionals</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5  col-lg-3 mx-auto py-2 pt-4 charchole">
          <div className=" text-center text-center border-0 charchole">
            <img src="Assets/Images/gif/Partners.gif" className="card-img-top partnersclients m-auto" alt="image not found" />
            <div className="card-body  white">
              <h2 className="card-title cardT fw-bold pt-4">
                {focus && <CountUp end={2}
                  duration={8.75} />}
                + Partners</h2>
              <p className="card-text">Each with 20+ years of experience</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5  col-lg-3 mx-auto py-2 pt-4 charchole">
          <div className=" text-center border-0 charchole">
            <img src="Assets/Images/gif/Clients.gif" className="card-img-top partnersclients m-auto" alt="image not found" />
            <div className="card-body white">
              <h2 className="card-title cardT w-bold pt-4">
                {focus && <CountUp end={120}
                  duration={3.75} />}
                + Clients</h2>
              <p className="card-text text-center">Majority Government, India and Abroad</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-5  col-lg-3 mx-auto py-2 pt-4 charchole">
          <div className=" text-center border-0 charchole">
            <img src="Assets/Images/gif/complete.gif" className="card-img-top partnersclients m-auto" alt="image not found" />
            <div className="card-body white">
              <h2 className="card-title cardT w-bold pt-4">
                {focus && <CountUp end={150}
                  duration={2.75} />}
                + </h2>
              <p className="card-text text-center"> Completed Projects</p>
            </div>
          </div>
        </div>
      </div>

    </ScrollTrigger>

  )
}