export const GetIntouchForm = () => {
    return (
        <>
            <div class="row" id="forminput">


                <div class="col-10 col-md-8 py-5 get-in-touch m-auto">
                    <h4 class="title">Get in touch</h4>
                    <form class="contact-form row" action="https://formspree.io/f/xrgwjprn" method="post">
                        <div class="form-field col-lg-6">
                            <input id="name" name="Name" class="input-text js-input" type="text" required />
                            <label class="label" for="name">Name</label>
                        </div>
                        <div class="form-field col-lg-6 ">
                            <input id="email" name="Email" class="input-text js-input" type="email" required />
                            <label class="label" for="email">E-mail</label>
                        </div>
                        <div class="form-field col-lg-6 ">
                            <input id="company" name="company" class="input-text js-input" type="text" required />
                            <label class="label" for="company">Company Name</label>
                        </div>

                        <div class="form-field col-lg-6 ">
                            <input id="phone" name="Phone" class="input-text js-input" type="tel" minlength="10" maxlength="10"
                                required />
                            <label class="label" for="phone">Contact Number</label>
                        </div>

                        <div class="form-field col-lg-12">
                            <input id="message" name="Message" class="input-text js-input" type="text" required />
                            <label class="label" for="message">Message</label>
                        </div>
                        <div class="form-field col-lg-12">
                            <input class="submit-btn" type="submit" value="Submit" onsubmit="return validateForm()" />
                        </div>
                    </form>
                </div>

                <div class="popup" id="successPopup">
                    <div class="popup-content">
                        {/* <span class="close" onclick="closePopup()"><img src="img/popup/close.png" alt="" style="width: 20px"></span>
    <!-- <img src="check-mark.png" alt="" style="width: 20%" class="checkpopimg"> --> */}
                        <h2 class="heading">Success!</h2>
                        <p>Your form has been submitted successfully.</p>
                    </div>
                </div>

            </div>
        </>
    )
}