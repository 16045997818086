import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { BiServices } from "./BiServices"
import { Info_BiSolution } from "./Info_BiSolution"

export const BiSolutions = ()=>{
    return(
        <>
        <Header active="Services"/>
        <HeroSection/>
        <Info_BiSolution/>
        <BiServices/>
        <BrightFutureTogether/>
        <Footer/>

        </>
    )
}