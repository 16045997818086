import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Ams = () => {
    return (
        <>
        <Header active="Products"/>
            <HeroSection />
            {/* ----------------------------------- */}


            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Asset Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                    A comprehensive computer system developed with web and mobile user interface for maintaining property inventory with geo-location at the village level within the District, Panchayat Samiti, and Gram Panchayat. Mobile application provided for uploading photos and videos related to property with geo-location on Google map. The mobile app captures location data using property latitude-longitude location and displays property locations on Google Maps. System allows for updating property details, conducting property valuations, and accessing property information based on its classification.
                    </p>

                    {/* <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p> */}
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Geolocation Integration</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Property Categorization</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Property Classification</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Property Valuation</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Document Management</b></p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Officer Level Dashboard for Monitoring</b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/ASSET_MANAGEMENT_SYSTEM.pdf')} 
                      className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}


            {/* --------------------------------- */}
            <Footer />
        </>
    )
}