export const AboutPage = () => {
    return (
        <>
            <div className="row " id="about_us">
                <div className="col-12 col-md-6 col-lg-6 px-4 px-lg-5 pt-lg-5 d-none d-lg-block ">
                    <img src="Assets/Images/seo-search-engine.jpg" alt="img not found" className="hroimgg " />
                    {/* <!-- <video width="100%" autoplay controls loop>
          <source src="img/About.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video> --> */}
                </div>
                <div className="col-12 col-md-10 col-lg-6 px-4 px-lg-5 " data-aos="fade-left">
                    <h5 className="fw-bold charcholecolor pt-4  pt-lg-5">ABOUT US</h5>

                    <div className="zoom-in-out-box2">
                        <hr />
                    </div>

                    <div className="  pb-3 mt-3  alignment">
                        <p className=" ">

                            <h4 className="fw-bold ">Welcome to CDAT</h4>
                            <p className="">Welcome to our esteemed company, a premier provider of exceptional software solutions.</p>

                            With a relentless commitment to excellence and a passion for innovation,
                            we specialize in delivering tailored software development services to businesses of all sizes. Our team of
                            highly skilled professionals combines technical expertise with creative prowess to craft sophisticated and
                            intuitive solutions that meet our clients' unique needs. We take pride in our customer-centric approach,
                            striving to understand and exceed expectations, while maintaining the highest standards of professionalism and
                            integrity. Partner with us to embark on a transformative journey and unlock your business's true potential.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 m-auto px-4 pt-lg-3 d-block d-lg-none pb-5">
                    <img src="Assets/Images/seo-search-engine.jpg" alt="img not found" className="hroimgg " />
                </div>

            </div>
            {/* <!--end of about us--> */}
        </>
    )
}