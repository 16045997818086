export const Vision = () => {
    return (
        <>
            <div className="row" id="vision">


                <div className="col-12  col-md-5 col-lg-5 py-5  mx-auto">
                    <img src="Assets/Images/why us 1.png" alt="" style={{ width: '100%' }} className="ds" />
                </div>
                <div className="col-12 col-lg-6 py-5 px-lg-5 mx-auto  alignment">
                    <h3 className="fw-bold colorcharchol text-center">Vision</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                    <p className="py-5 pt-lg-5 pb-lg-0 px-2 ">At CDAT, our vision is to be a globally recognized leader in providing innovative
                        software solutions that empower businesses to thrive in the digital era. We envision a future where
                        our cutting-edge technologies and tailored services drive digital transformation across industries,
                        enabling organizations to achieve unprecedented levels of success. Through our unwavering commitment
                        to excellence, continuous innovation, and customer-centric approach, we aim to be the trusted
                        partner for businesses seeking to leverage technology for sustainable growth and competitive
                        advantage. By staying at the forefront of emerging technologies and embracing a culture of
                        collaboration, we strive to shape the future of software solutions and make a positive impact on the
                        businesses we serve.</p>
                </div>
            </div>
        </>
    )
}