import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { FinancialServicesAdvantages } from "./FinancialServicesAdvantages"
import { FinancialServicesInfo } from "./FinancialServicesInfo"
import { FinancialServicesKeyFeatures } from "./FinancialServicesKeyFeatures"

export const FinancialServices =()=>{
    return(
        <>
<Header active="Expertise"/>
<HeroSection/>
<FinancialServicesInfo/>
<FinancialServicesKeyFeatures/>
<FinancialServicesAdvantages/>
<BrightFutureTogether/>
<Footer/>
        </>
    )
}