export const InfoDataAnalytics = () => {
    return (
        <>
            <div className="row bgdotimg " id="Data_Analytics">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Data Analytics</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p className=" alignment">
                        Our expertise in data analysis, statistical modeling, and predictive analytics enables us to uncover
                        meaningful patterns and trends that drive informed decision-making.

                        At CDAT Company, we are passionate about harnessing the power of data analytics to drive your business
                        forward. Our team of experienced data scientists and analysts works closely with you to understand your unique
                        challenges and deliver tailored solutions that unlock the full potential of your data assets.
                    </p>
                    <p className=" alignment">
                        Our data analytics services help businesses extract meaningful insights from their data.

                        We leverage advanced analytics techniques and tools to uncover patterns, trends, and correlations, enabling
                        you to make data-driven decisions.
                    </p>

                </div>
            </div>

            {/* ------------------------------ */}
            <div className="row" id="BI solutions info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Data Analytics Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
            </div>

            {/* -------------------------------- */}

            
    <div className="row services_shadow orange mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/Data_Analytics/exploration.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>Data Exploration and Preparation</b></h4>

        <p className="p-2 pb-lg-5 ">We dive deep into your data, exploring its intricacies and identifying relevant
          variables. Our experts clean, transform, and organize your data to ensure accuracy and consistency, setting
          the stage for effective analysis.
        </p>
      </div>
    </div>

    <div className="row services_shadow green mx-auto my-4 sky">
      <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
        <img src="Assets/Images/Data_Analytics/Statistical Analysis.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
        <h4 className="pt-3  px-2"><b>Statistical Analysis</b></h4>

        <p className="p-2 pb-lg-4">We employ advanced statistical techniques to analyze your data and derive meaningful
          insights. From descriptive statistics to regression analysis and hypothesis testing, we uncover the hidden
          patterns and relationships within your data.
        </p>
      </div>

      <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
        <img src="Assets/Images/Data_Analytics/Statistical Analysis.png" alt="" srcset="" style={{width: '50%'}}/>
      </div>
    </div>

    <div className="row services_shadow pink mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/Data_Analytics/Predictive Analytics.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>Predictive Analytics</b></h4>

        <p className="p-2 pb-lg-5 ">Our team leverages predictive modeling to forecast future outcomes and trends. By
          analyzing historical data and using algorithms, we help you make data-driven predictions that guide your
          strategic planning and decision-making processes.
        </p>
      </div>
    </div>

    <div className="row services_shadow lightblue mx-auto my-4 sky">
      <div class="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
        <img src="Assets/Images/Data_Analytics/Data Visualization.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
        <h4 className="pt-3  px-2"><b>Data Visualization</b></h4>

        <p className="p-2 pb-lg-4">We create visually engaging dashboards and reports that present your data in a clear and
          understandable format. Our interactive visualizations allow you to explore data intuitively and gain valuable
          insights at a glance.
        </p>
      </div>

      <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
        <img src="Assets/Images/Data_Analytics/Data Visualization.png" alt="" srcset="" style={{width: '50%'}}/>
      </div>
    </div>

    <div className="row services_shadow orange mx-auto my-4">
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
        <img src="Assets/Images/Data_Analytics/Decision Making.png" alt="img not found" srcset="" style={{width: '50%'}}/>
      </div>

      <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
        <h4 className="pt-3 charcholecolor px-2 "><b>Data-Driven Decision Making</b></h4>

        <p className="p-2 pb-lg-5 ">With our data analytics services, you can make informed decisions based on evidence
          rather than assumptions. We provide you with actionable insights that empower you to optimize processes,
          identify growth opportunities, and mitigate risks.
        </p>
      </div>
    </div>

        </>
    )
}