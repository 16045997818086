export const DomainWeWork = () => {
  return (
    <>
      <div className="row p-3 p-lg-5 text-center graybg_second">

        <div className="col-12 m-auto py-2 mb-lg-3 mt-5 text-center ">
          <h3 className="fw-bold "> DOMAIN'S WE WORK FOR </h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>
        </div>



        <div className="col-12 col-md-4 col-lg-4 mt-3  ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              Healthcare</div>
            <div className="testimonial_arrow"></div>
            <div className="px-0 pb-1">
              <p className="border-bottom py-3">National Health Mission</p>
              <p className=" py-2">Pharma</p>
            </div>
          </div>

        </div>


        <div className="col-12 col-md-4 col-lg-4 mt-3 ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              HRMS</div>
            <div className="testimonial_arrow"></div>
            <div className="pb-1">
              <p className="border-bottom py-3">Zilla Parishad</p>
              <p className=" py-2">Municipal Corporation</p>
            </div>
          </div>

        </div>


        <div className="col-12 col-md-4 col-lg-4 mt-3 ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              E-Commerce</div>
            <div className="testimonial_arrow"></div>
            <div className=" pb-1">
              <p className="border-bottom py-3">Agriculture</p>
              <p className=" py-2">Women's Self-Help Groups</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              Water & Agriculture</div>
            <div className="testimonial_arrow"></div>
            <div className="px-5 pb-1">
              <p className="border-bottom py-3">Soil & Water Harvesting </p>
              <p className=" py-2">Farmer Producer Company</p>
            </div>
          </div>

        </div>


        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain ">
              Manufacturing & Service Industry</div>
            <div className="testimonial_arrow"></div>
            <div className="px-5 pb-1">
              <p className="border-bottom py-3">Labour Services</p>
              <p className="border-bottom py-2">Production Industry</p>
              <p className=" py-2">Service Industry</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              e-Governance</div>
            <div className="testimonial_arrow"></div>
            <div className=" pb-1">
              <p className="border-bottom pt-3 pb-3">Muncipal Corporation</p>
              <p className="border-bottom pt-1 pb-3">Zilla Parishad </p>
              <p className="border-bottom pt-1 pb-3">Police Department</p>
              <p className="py-2">State Department</p>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}