import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';





export const Clients = () => {

  const options = {
    // item: 1,
    500: {
      items: 8
    }
  }
  return (
    <>
      <div className="row charchole py-5 " id="client">
        <div className="col-10 col-md-10 mx-auto text-center">
          <h3 className="fw-bold white"> CLIENT'S</h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>

          <p className=" pt-4 pb-3 white">At CDAT, we take immense pride in the trust our clients place in us for their
            website needs. With years of experience and a proven track record of excellence, we have earned a reputation
            for delivering exceptional web solutions that exceed expectations.
          </p>
        </div>

        <div className="col-12 m-auto pt-4">

          <OwlCarousel className='owl-theme'
            items={2}
            dots={false}
            loop={true}
            autoplay={true}
            //  autoplayHoverPause={false}
            autoplayTimeout={1000}
            //  animateIn="fadeIn"
            //  animateOut="fadeOut"
            lazyLoad={false}
            smartSpeed={10000}
            margin={6}
            responsive={options}

            nav>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/mahatma phule_krishi_vidhyapith-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>

            <div className="item ">
              <h4><img src="Assets/Images/clients_all/igp_kolhapur-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/Agriculture _Department-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/Bhivandi_Nijampur_Municipal_Corporation-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/chhattisgad-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/erp_gayatari-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/ey-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/gkmarket-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/pcmc-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/GCPI_logo-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/mazi_mulgi-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/pune_metro-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>

            <div className="item ">
              <h4><img src="Assets/Images/clients_all/zp_raigad-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/samarth_erp-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/pcmc-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>
            <div className="item ">
              <h4><img src="Assets/Images/clients_all/malegaon-01.png" className="img-responsive ds"
                alt="logo1" />
              </h4>
            </div>





          </OwlCarousel>;

        </div>

      </div>
    </>
  )
}