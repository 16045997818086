import { Footer } from "./Footer"
import { Header } from "./Header"
import { HeroSection } from "./HeroSection"

export const TermsConditions = () => {
    return (
        <>
            <Header />
            <HeroSection />

            {/* -------------- */}
            <div className="row" id="privacy data">
                <div className="col-12 text-center">
                    <h3 className="pt-5  text-black blue_text fw-bold">Terms & Conditions</h3>
                    <div className="zoom-in-out-box2 mb-5 mx-auto  text-center">
                        <hr />
                    </div>
                </div>

                <div className="col-10 mx-auto text-center">
                    <p>
                        Welcome to <b>CDAT</b>! These terms and conditions outline the rules and regulations for using our software and website. By accessing or using our services, you agree to comply with these terms. Please read them carefully and ensure your understanding before using our services..
                    </p>
                </div>

                <div className="col-10 py-3 mx-auto text-start">
                    <p><b>Acceptance of Terms:</b></p>
                    <p>By accessing our website and using our software, you acknowledge and agree to be bound by these terms and conditions.</p>

                    <p className="mt-4"><b>Intellectual Property:</b></p>
                    <p>All content, trademarks, logos, and software on our website and applications are the property of CDAT or our licensors. You are prohibited from using, copying, modifying, or distributing them without our prior written consent.</p>

                    <p className="mt-4"><b>User Conduct:</b></p>
                    <p>You agree to use our services in compliance with applicable laws and regulations. You shall not engage in any activity that may disrupt or interfere with the proper functioning of our software or website.</p>

                    <p className="mt-4"><b>Privacy :</b></p>
                    <p> Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal information.</p>

                    <p className="mt-4"><b>Limitation of Liability:</b></p>
                    <p>  CDAT and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use our services. We do not guarantee the accuracy, reliability, or availability of our software or website.</p>

                    <p className="mt-4"><b>Third-Party Links:</b></p>
                    <p>Our website and software may contain links to third-party websites. We do not endorse or have control over the content, privacy policies, or practices of these websites. You access them at your own risk.</p>

                    <p className="mt-4"><b>Termination:</b></p>
                    <p> We reserve the right to terminate or suspend your access to our services at any time, without prior notice, if you violate these terms and conditions. Upon termination, you must cease using our services.</p>


                    <p className="mt-4"><b>Governing Law:</b></p>
                    <p>These terms and conditions shall be governed by and construed in accordance with the laws of [INDIA/MAHARASHTRA]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in [INDIA/MAHARASHTRA].</p>

                    <p className="mt-4"><b>Modifications:</b></p>
                    <p> We may modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Your continued use of our services after changes are made constitutes acceptance of those changes.</p>
                    <p>If you have any questions or concerns about these terms and conditions, please contact us at <a href="tel:9834340716" className="colorcharchol">9834340716</a></p>
                <p>These Terms and Conditions were last updated on [ 30 March 2024 ].</p>
                </div>
            </div>
            {/* <!--end of row privacy data--> */ }



            < Footer />
        </>
    )
}