import { BrightFutureTogether } from "../BlogPage/BrightFutureTogether"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { Christmas } from "./Christmas"
import { Diwali } from "./Diwali"
import { FoundationDay } from "./FoundationDay"
import { FriendshipDay } from "./FriendshipDay"
import { GaneshChaturthi } from "./GaneshChaturthi"
import { Holi } from "./Holi"
import { IndependenceDay } from "./IndependenceDay"
import { WomensDay } from "./WomensDay"

export const Events =()=>{
    return(
        <>
            <Header active="About"/>
            <HeroSection/>
            <WomensDay/>
            <GaneshChaturthi/>
            <FriendshipDay/>
            <Diwali/>
            <FoundationDay/>
            <IndependenceDay/>
            <Christmas/>
            <Holi/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}