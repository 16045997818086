import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"


export const TreeRiskAssessmentSystem = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* --------------------------------- */}
            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Tree Risk Assessment System</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p class="alignment text-center">
                    The proposed system is a web and mobile based application with centralized database. The proposed system should be able to analyze the advanced diagnosis of the tree using Resistograph for trees which have potential to analyze different concerns of the tree. A detailed analysis of tree can be done in the proposed system to evaluate the overall structure and condition of the tree and suggest the preventive measures. The proposed system will provide the facility to generate report for individual assessment of the tree.
                    </p>

                </div>

            </div>
            {/* <!--end of AMS sopi--> */}


            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Tree Inventory & Geo-Tagging Integration</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Risk Assessment & Qualitative Data Analysis</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Job Creation, Assignment, and Tracking</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Mitigation Action Tracking</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Dashboard and Reporting</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Real-Time Data Synchronization and Mobile Integration</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/Tree_Risk_Assessment_System.pdf')} 
                        // href="http://new.cdatinfotech.com/doc/Public_Grievance.pdf"
                         class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}