export const Info_BiSolution =()=>{
    return(
        <>
            <div className="row bgdotimg " id="BI solutions">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">Business Intelligence Solutions</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-10 mx-auto py-3 text-center  alignment">
                <p className=" alignment">
                    Our BI solutions provide you with the tools and insights needed to transform raw data into actionable intelligence. We help you collect, organize, analyze, and visualize data in a meaningful way, enabling you to gain valuable insights into your business performance, customer behavior, market trends, and more. We provide robust business intelligence solutions that empower businesses to gain valuable insights from their data. Our BI solutions enable data analysis, visualization, and reporting, allowing you to make informed decisions and drive business growth.
                </p>
                <p className=" alignment">
                    At CDAT Company, we are committed to delivering powerful BI solutions that unlock the potential of your data. Our expertise in BI development, data integration, visualization, and analytics enables us to tailor solutions to your specific needs, ensuring you get the most out of your data assets. Experience the power of our BI solutions and take your business to new heights.
                </p>

            </div>
        </div> 
        </>
    )
}