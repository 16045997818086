export const InfoProductDevelopment = () => {
    return (
        <>
            <div className="row bgdotimg " id="Customized_Solution">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Product Development</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        At CDAT Company, we are committed to delivering customized solutions that perfectly align with your
                        business objectives. Our expertise in understanding your unique requirements and developing tailored
                        software solutions ensures that you get the most out of your investment.

                        Bring Your Vision to Life with CDAT Company's Product Development Services.

                        Our team of skilled professionals is dedicated to guiding you through every step of the product
                        development journey, from concept to launch.
                    </p>
                    <p>
                        At CDAT Company, we are dedicated to turning your product vision into reality. With our expertise in
                        product development, we strive to create innovative and user-friendly products that not only meet
                        your business goals but also delight your customers.
                    </p>

                </div>
            </div>
            {/* <!--end of row Customized Solution content--> */}

            <div className="row" id="Graphic_Design_info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Product Development Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Development/idea.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Idea Generation and Conceptualization</b></h4>

                    <p className="p-2 pb-lg-5 ">We collaborate with you to generate innovative ideas and concepts for your
                        product. Our team conducts thorough market research, competitor analysis, and user insights to
                        ensure that your product meets the needs of your target audience.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Development/prototype.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>
                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Product Design and Prototyping</b></h4>

                    <p className="p-2 pb-lg-4">We transform your ideas into tangible designs and interactive prototypes. Our
                        experienced designers create visually appealing and user-centric product designs that capture the
                        essence of your vision and provide a foundation for development.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Development/prototype.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Development/Agile Development.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Agile Development</b></h4>

                    <p className="p-2 pb-lg-5 ">We follow an agile development approach to bring your product to life efficiently and
                        effectively. Our development team utilizes the latest technologies and best practices to build robust,
                        scalable, and high-performance software solutions.
                    </p>
                </div>
            </div>

            <div className="row services_shadow lightblue mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Development/quality-assurance.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Quality Assurance and Testing</b></h4>

                    <p className="p-2 pb-lg-4">We conduct rigorous testing throughout the development process to ensure that your
                        product meets the highest quality standards. Our QA specialists perform comprehensive testing to identify and
                        resolve any issues, ensuring a seamless user experience.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Development/quality-assurance.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Development/Support-.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b> Launch and Post-Launch Support</b></h4>

                    <p className="p-2 pb-lg-5 ">We assist you in launching your product successfully in the market. Our team provides
                        ongoing support and maintenance to address any issues and make necessary enhancements, ensuring the continued
                        success of your product.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Development/Improvement.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Continuous Improvement</b></h4>

                    <p className="p-2 pb-lg-4">We believe in the continuous improvement of your product to stay ahead of the
                        competition. Our team analyzes user feedback, monitors performance metrics, and identifies areas for
                        enhancement, allowing your product to evolve and adapt to changing market demands.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Development/Improvement.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>
        </>
    )
}