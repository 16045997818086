import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { Mission } from "./Mission"
import { Value } from "./Value"
import { Vision } from "./Vision"

export const WhyUs = () => {
    return (
        <>
            <Header active="About"/>
            <HeroSection />
            <Vision />
            <Value />
            <Mission />
            <BrightFutureTogether />
            <Footer />

        </>
    )
}