export const InfoProductMaintenance = () => {
    return (
        <>

            <div className="row bgdotimg " id="Product_Maintenance">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Product Maintenance</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr/>
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Keep Your Products Running Smoothly with CDAT Company's Product Maintenance Services.

                        At CDAT Company, we understand the importance of maintaining your software products to ensure their
                        optimal performance and longevity. Our product maintenance services are designed to keep your
                        products up-to-date, secure, and fully functional throughout their lifecycle.
                    </p>
                    <p>
                        At CDAT Company, we are committed to ensuring the smooth operation of your software products through
                        our comprehensive maintenance services. Our goal is to keep your products reliable, secure, and
                        optimized, allowing you to focus on your core business operations.
                    </p>

                </div>
            </div>
            {/* <!--end of row Product Maintenance content--> */}

            <div className="row" id="Graphic_Design_info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Product Maintenance Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr/>
                    </div>
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Maintenance/Bug.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>
                        Bug Fixes and Issue Resolution</b></h4>

                    <p className="p-2 pb-lg-5 ">Our dedicated team of experts is ready to address any issues or bugs that may
                        arise in your software products. We conduct thorough debugging and troubleshooting to identify and
                        resolve issues promptly, minimizing any disruptions to your operations.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Maintenance/Updates.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>
                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Updates and Enhancements</b></h4>

                    <p className="p-2 pb-lg-4">We provide regular updates and enhancements to keep your products aligned with
                        the latest industry trends and technologies. Our team stays updated with emerging technologies and
                        incorporates new features and functionalities that improve the user experience and add value to your
                        products.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Maintenance/Updates.png" alt="" srcset="" style={{width: '50%'}} className="ds" />
                </div>
            </div>

            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Maintenance/Performance_O.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Performance Optimization</b></h4>

                    <p className="p-2 pb-lg-5 ">We optimize the performance of your products to ensure they operate at their
                        peak efficiency. Our team conducts performance analysis, identifies bottlenecks, and implements
                        optimizations to enhance speed, responsiveness, and overall user satisfaction.
                    </p>
                </div>
            </div>

            <div className="row services_shadow lightblue mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Maintenance/Security_m.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Security Patches and Vulnerability Fixes</b></h4>

                    <p className="p-2 pb-lg-4">We prioritize the security of your products by applying timely security patches
                        and fixing vulnerabilities. Our experts conduct regular security audits and implement necessary
                        measures to protect your products from potential threats and ensure data integrity.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Maintenance/Security_m.png" alt="" srcset="" style={{width: '50%'}} className="ds" />
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/Product_Maintenance/Compatibility.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b> Compatibility and Integration</b></h4>

                    <p className="p-2 pb-lg-5 ">We ensure that your products remain compatible with evolving technologies and
                        platforms. Our team monitors industry standards and updates your products to ensure seamless
                        integration with new systems, APIs, and third-party applications.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/Product_Maintenance/Technical_Support.png" alt="img not found" srcset="" style={{width: '50%'}} className="ds" />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Technical Support and Maintenance</b></h4>

                    <p className="p-2 pb-lg-4">We provide ongoing technical support to address any queries or issues related to your
                        products. Our support team is available to assist you with product-related inquiries, troubleshooting, and
                        guidance, ensuring that you have the necessary support when you need it.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/Product_Maintenance/Technical_Support.png" alt="" srcset="" style={{width: '50%'}} className="ds" />
                </div>
            </div>
        </>
    )
}