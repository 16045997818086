export const HealthcareKeyFeatures = ()=>{
    return(
        <>
  <div className="row py-5 px-4" id="key">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p> <b>Industry-specific Expertise</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p> <b>Criticality of Software Solutions</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p> <b>HIPAA Compliance</b> </p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p> <b>Electronic Medical Records (EMR) Management</b></p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p><b>Superior Patient Care and Compliance</b></p>
                </div>
            </div>

            <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div className="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="icon not found" style={{width: '40px'}}/>
                    <p><b>Telemedicine Platforms</b></p>
                </div>
            </div>
        </div>
        </>

    )
}