export const KeyFeatures = () => {
    return (
        <>
            <div className="row py-5 px-4" id="key">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Demonstrated Success</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Tailored Solutions</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Streamlined Administrative Processes</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Enhanced Citizen Services</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>In-depth Understanding of Government Operations</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Robust Security and Data Protection</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}