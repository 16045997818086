export const HealthcareAdvantages = () => {
    return (
        <>
            <div class="row charchole py-4 mb-5 px-lg-4" id="government_content">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold white text-center">ADVANTAGES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border1 text-center px-2">
                        <img src="Assets/Images/healthcare/Industry Expertise.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3"> Industry Expertise</h5>
               
                        <input type="checkbox" hidden class="read-more-state" id="read-more6" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                Our content highlights our deep understanding of the healthcare and life sciences industry. This advantage positions us as knowledgeable experts who comprehend the unique challenges,
                                <span className="read-more-target ps-2">
                                    regulations, and requirements of the sector. It instills confidence in readers that we can provide tailored solutions that address their specific needs.
                                </span>
                            </p>
                        </div>
                        <label for="read-more6" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2">
                    <div class=" border border2 text-center px-2">
                        <img src="Assets/Images/healthcare/Criticality.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Criticality of Software Solutions</h5>
                     

                        <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the crucial role of software solutions in the healthcare and life sciences industry. Our content communicates the advantage of leveraging technology to enhance
                                <span className="read-more-target ps-2">
                                patient care, streamline processes, and improve overall efficiency. This resonates with healthcare organizations looking for innovative solutions to meet their evolving needs.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border3 text-center px-2">
                        <img src="Assets/Images/healthcare/Compliance.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">HIPAA Compliance</h5>
                        
                        <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content showcases our expertise in developing HIPAA-compliant systems. This advantage assures readers that we prioritize data privacy and security, which is of utmost
                                <span className="read-more-target ps-2">
                                which is of utmost importance in the healthcare industry. It positions us as a trusted partner that understands and adheres to the stringent regulatory requirements governing sensitive patient data.
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border4 text-center px-2">
                        <img src="Assets/Images/healthcare/Electronic.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Electronic Medical Records (EMR) Management</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We highlight our proficiency in EMR management. This advantage demonstrates our ability to design and implement efficient systems  
                                <span className="read-more-target ps-2">
                                that centralize and organize patient records, enabling healthcare providers to access critical information easily. It showcases our commitment to improving the accuracy, accessibility, and efficiency of healthcare data management.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border5 text-center px-2">
                        <img src="Assets/Images/healthcare/Telemedicine.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Telemedicine Platforms</h5>
                       
                        <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content emphasizes our capability to develop telemedicine platforms. This advantage highlights our response to the growing demand for remote healthcare  services, enabling
                                <span className="read-more-target ps-2">
                                healthcare organizations to deliver care beyond traditional in-person settings. It positions us as a forward-thinking partner that helps healthcare providers adapt to the changing landscape of patient care.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border6 text-center px-2">
                        <img src="Assets/Images/healthcare/Patient Care.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Superior Patient Care and Compliance</h5>
                     
                        <input type="checkbox" hidden class="read-more-state" id="read-more1" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the key advantage of enabling healthcare organizations to deliver superior patient care while ensuring data privacy and compliance.  Our solutions not only
                                <span className="read-more-target ps-2">
                                Our solutions not only enhance operational efficiency but also contribute to improved patient outcomes, seamless communication, and better overall care experiences. This advantage resonates with healthcare providers seeking to enhance patient satisfaction and maintain regulatory compliance.
                                </span>
                            </p>
                        </div>
                        <label for="read-more1" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

            </div>
        </>
    )
}