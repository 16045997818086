export const AgricultureAdvatages =()=>{
    return(
        <>
            

    <div class="row charchole py-4 mb-5" id="government_content">
      <div class="col-12 col-md-10 mx-auto py-4">
        <h3 class="fw-bold white text-center">ADVANTAGES</h3>

        <div class="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 white py-2 ">
        <div class=" border border1 text-center px-2">
          <img src="Assets/Images/Agriculture/Holistic Approach.png" alt="" style={{width:'120px'}} class="mt-2 ds" />
          <h5 class="pt-4 fw-bold pb-3"> Holistic Approach</h5>
          <p> With our ITC model, we take a holistic approach to agriculture, incorporating data-driven insights,
            technology integration, and effective communication for comprehensive farm management.
            </p>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 white py-2">
        <div class=" border border2 text-center px-2">
          <img src="Assets/Images/Agriculture/Data-Driven Decisions.png" alt="" style={{width:'120px'}}class="mt-2 ds"/>
          <h5 class="pt-4 fw-bold pb-3">Data-Driven Decisions</h5>
          <p>Harnessing the power of data analytics, we provide farmers with valuable insights to make informed
            decisions, optimize resource allocation, and maximize yields.
            </p>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 white py-2 ">
        <div class=" border border3 text-center px-2">
          <img src="Assets/Images/Agriculture/Precision Agriculture.png" alt="" style={{width:'120px'}}class="mt-2 ds"/>
          <h5 class="pt-4 fw-bold pb-3">Precision Agriculture</h5>
          <p>Through the ITC model, we introduce precision agriculture practices, using advanced technologies like GPS,
            drones, and sensors for precise crop management, leading to enhanced productivity.
            </p>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 white py-2 mx-auto">
        <div class=" border border4 text-center px-2">
          <img src="Assets/Images/Agriculture/Sustainable Farming.png" alt="" style={{width:'120px'}}class="mt-2 ds" />
          <h5 class="pt-4 fw-bold pb-3">Sustainable Farming</h5>

          <p>Our ITC model promotes sustainable farming practices, promoting conservation, minimizing waste, and
            preserving natural resources for a greener future.reliability throughout the employee lifecycle.
       </p>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 white py-2 mx-auto">
        <div class=" border border5 text-center px-2">
          <img src="Assets/Images/Agriculture/monitoring.png" alt="" style={{width:'120px'}} class="mt-2 ds"/>
          <h5 class="pt-4 fw-bold pb-3">Real-Time Monitoring</h5>
          <p> Stay updated with real-time monitoring of weather conditions, soil health, and crop growth, allowing
            prompt actions to address challenges and improve outcomes.
            </p>
        </div>
      </div>
      </div>
        </>
    )
}