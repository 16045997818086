
import { useNavigate } from "react-router-dom"

export const ClientSays = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="row p-3 p-md-5 pt-5 ">
        <div className="col-12 col-lg-10 px-lg-5 mx-auto text-center">
          <h3 className="fw-bold pb-3">WHAT CLIENT SAY?</h3>
          <h6 className="border-bottom pb-3 border-dark lh-lg ">These are some of the valuable opinions of our clients. We
            appreciate all the opinions of the clients ever bad feedbacks are welcome.</h6>
        </div>

        {/* <div className="col-12 col-md-4 mt-5">
          <div className="px-3 pt-3 border charchole client_says">
            <p className="text-white font_size">"Under tight deadlines and with high
              expectations, CDAT has delivered the Public Grievance Management System both Mobile App and Web Application
              suited to the requirement of Urban Local Body , The Citizens of City have given a overwhelming reponse , The
              app has seen 30k plus downloads and still counting in very short time , Well Done Team CDAT".</p>
          </div>
          <div className="client_arrow"></div>
          <div className="pt-2">
            <div className="row">
              <div className="col-3">
                <img src="Assets/Images/Home/amil.jpg" alt="" className="client_img" />
              </div>
              <div className="col-9">
                <span className="fw-bold">Commissioner</span><br />
                <p className="font_size pt-2"><b>Nagpur Municipal Corporation </b></p>
              </div>

            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 mt-5">
          <div className="px-3 pt-3 border charchole client_says">
            <p className="text-white font_size" >E Tapal Police Department is a internet & intranet base solution for all
              government functionaries to monitor their Daily Branch, Section and
              Table wise letter and file movement of their own Office. LFMS is helping
              for electronic letter inwards, letter movement from one desk to another
              desk, creation of desk wise files and letter draft for approval,
              dispatch of letters, desk wise setting due dates and reminders, desk
              wise queries and report and migration of letters and files from one desk
              to another desk.</p>
          </div>
          <div className="client_arrow"></div>
          <div className="pt-2">
            <div className="row">
              <div className="col-3">
                <img src="Assets/Images/Home/avtar.jpg" alt="" className="client_img" />
              </div>
              <div className="col-9">
                <span className="fw-bold">Superintendent of Police</span><br />
                <p className="font_size pt-2"><b>District Ahmednagar</b></p>
              </div>

            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 mt-5">
          <div className="p-3 border charchole client_says">
            <span className="text-white font_size">"The Letter and File Management System designed
              and Developed by CDAT has really brought transparency in the Government Domain , The software is very light
              , easy to operate with interactive dashboards, CDAT is a company who understands the government domain,
              Their expertise empower the digital transformation in Government Offices smoothly and efficiently, Good Job
              Team CDAT".</span>
          </div>
          <div className="client_arrow"></div>
          <div className="pt-2">
            <div className="row">
              <div className="col-3">
                <img src="Assets/Images/Home/amil.jpg" alt="" className="client_img" />
              </div>
              <div className="col-9">
                <span className="fw-bold">Project Director</span><br />
                <p className="font_size pt-2"><b>State Agriculture Department</b></p>
              </div>

            </div>
          </div>
        </div> */}

        <div className="col-12 col-md-6 col-lg-5 mx-auto pt-5">
          <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow rounded">

            <video width="100%" height="300px" poster="Assets/Images/video/nagpur-01.PNG" controls>
              <source src="Assets/Images/video/nmc.mp4" type="video/mp4" />
            </video>

            <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
            <p className="mb-0 mt-2 font-italic fs-6 pt-3">"Under tight deadlines and with high expectations, CDAT has delivered
              the Public Grievance Management System both Mobile App and Web Application suited to the requirement of
              Urban Local Body , The Citizens of City have given a overwhelming reponse , The app has seen 30k plus
              downloads and still counting in very short time , Well Done Team CDAT".</p>
            <footer className="blockquote-footer pt-4 mt-4 border-top"><b className="text-dark">Commissioner</b>
              <cite title="Source Title"> Nagpur Municipal Corporation</cite>
            </footer>
          </blockquote>
        </div>
        <div className="col-12 col-md-6 col-lg-5 mx-auto pt-5">

          <blockquote className="blockquote blockquote-custom bg-white pt-5 pb-4 px-3 shadow rounded">

            <iframe width="100%" height="300px" src="https://www.youtube.com/embed/pW0OdIeWrbk"
              title="Ahmednagar पोलिसांची नागरिकांसाठी &#39;ई-टपाल&#39; प्रणाली, पोलीस अधीक्षक Manoj Patil यांची संकल्पना |Police"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>

            <div className="blockquote-custom-icon bg-golden shadow-sm "><i className="fa fa-quote-left text-white"></i></div>
            <p className="mb-0 mt-2 font-italic fs-6 pt-3">
              E Tapal Police Department is a internet & intranet base solution for all government functionaries to monitor their Daily Branch, Section and Table wise letter and file movement of their own Office. LFMS is helping for electronic letter inwards, letter movement from one desk to another desk, creation of desk wise files and letter draft for approval, dispatch of letters, desk wise setting due dates and reminders, desk wise queries and report and migration of letters and files from one desk to another desk.

            </p>

            <footer className="blockquote-footer pt-4 mt-lg-4 border-top"><b className="text-dark">Superintendent of Police</b>
              <cite title="Source Title"> District Ahmednagar</cite>
            </footer>

          </blockquote>

        </div>


        <div className="text-center col-lg-9 mx-auto ">
          <h5 className="lh-lg client_title">"Perfection is Achieved Not When There Is Nothing More to Add, But When There Is
            Nothing Left to Take Away"</h5>
        </div>

        <div className="col-12 pt-5 pt-lg-0 mt-4">
          <p className="text-center ">

            <button onClick={() => navigate('/ClientReviews?ScreenName=Clients Review&Name=Clients')} type="submit" className="silver_bg px-4 py-3">See More Reviews</button>
          </p>
        </div>
      </div>
    </>
  )
}