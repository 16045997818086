import { useNavigate } from "react-router-dom"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Products = () => {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <HeroSection />

            {/* --------------------- */}

            <div className="row ">


                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 mrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Medical Reimbursement Management System (MRMS)
                                        </h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/mrms_computer-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  ams_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Asset Management System (AMS)</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/AMShero.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5 ">
                    <a href="javascript:void(0)" onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 water_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">System of Pension Illustration (SOPI)</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/pension__computer-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5 ">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/MedicineStockManageSystem?ScreenName=Medicine Stock Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 medicine_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Medicine Stock Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/MEDICINE_INVENTORY_COMPUTER-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  ptms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Project Tracking & Monitoring System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/PTMS.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Health Cibil</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/healthcibilhero-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gk_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Global Kisan Market</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/gk_market-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 gazzet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Police Gazette Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/police_gaztte_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />

                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gaytri_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Agriculture and Manufacturing Industry ERP
                                        </h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/AFM_heero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 samarth_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Manufacturing and Service Industry ERP</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/samarth.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  mrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Letter & File Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Lfms_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 toilet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Online Letter System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/etapal_hero.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 hrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Human Resource Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Hrms_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  sewerage_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Smart Labor Application</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/smart_labor_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Nursing Home Registration System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/nursing_home.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 water_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Road (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Road_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  gaytri_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Sewerage (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/swerage_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Garden?ScreenName=Monitoring of Garden (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gazzet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Garden (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/garden_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5  stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Water Supply (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/water_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5  medicine_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of STP (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/stp_product-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-5 px-3 p-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PublicGrievance?ScreenName=Public Grievance Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 toilet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Public Grievance Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/public_grievance_management_system_screen_hero.png"
                                            width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>




            </div>

            {/* --------------------- */}
            <Footer />
        </>
    )

}