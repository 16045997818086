import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { CultureInfo } from "./CultureInfo"

export const Culture =()=>{
    return(
        <>
            <Header  active="About"/>
            <HeroSection />
            <CultureInfo/>
            <BrightFutureTogether />
            <Footer />
        </>
    )
}