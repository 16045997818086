import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { HrmsAdvantages } from "./HrmsAdvantages"
import { HrmsInfo } from "./HrmsInfo"


export const HRMS =()=>{
    return(
        <>
<Header active="Expertise"/>
<HeroSection/>
<HrmsInfo/>
{/* HrmsKeyFeatures/> */}
<HrmsAdvantages/>
<BrightFutureTogether/>
<Footer/>
        </>
    )
}