import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { Advantages } from "./Advantages"
import { GovenmentSector } from "./GovenmentSector"
import { KeyFeatures } from "./KeyFeatures"

export const Egovernance = () => {
    return (
        <>
            <Header active="Expertise"/>
            <HeroSection />
            <GovenmentSector/>
            <KeyFeatures/>
            <Advantages/>
            <BrightFutureTogether/>
            <Footer />
        </>
    )
}