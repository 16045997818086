export const Ideas = () => {
  return (
    <>
      <div className="row p-5 ideas ">
        <h4 className="border-bottom pb-3 silver">Your Idea!</h4>
        <div className="col-12 col-lg-6 pe-lg-5 ">

          <p className="pt-5 fw-light gray pe-lg-5 ">Every startup is born through an idea. Oftentimes this idea forms the
            solution to a problem that you identified. There might be a problem that you are constantly thinking about and
            all of a sudden it hits you: you got the solution right in front of your eyes and you ask yourself how to take
            this further. Could it be a real business? How can you tell if your idea is good enough?
          </p>
          <p className="gray pe-lg-5"> Let us give you some guidance in this very exciting phase of evaluating and
            conceptualizing your idea</p>
        </div>

        <div className="col-12 col-lg-6 ps-lg-5">

          <form action="https://formspree.io/f/xnqkeqqj" method="post">
            <div className="row pt-5">
              <div className="col-12 col-lg-6 ">
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="Name" name="Name" placeholder="Enter Your Name" required />
                  <label for="name">Enter Your Name</label>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-floating mb-3">
                  <input type="email" className="form-control" id="Email" name="Email" placeholder="Enter Your Email"
                    required />
                  <label for="Email">Enter Your Email </label>
                </div>
              </div>
              <div className="col-12 pt-2">
                <div className="form-floating">
                  <input type="tel" className="form-control" id="Mobile" name="Mobile" placeholder="Enter Your Mobile No"
                    minlength="10" maxlength="10" required />
                  <label for="Mobile">Enter Your Mobile No</label>
                </div>
              </div>
              <div className="col-12 pt-4 ">
                <div className="form-floating text-white">
                  <input type="file" className="file_chosen" id="File" name="File" required />
                </div>
              </div>
              <div className="col-12 pt-5 pt-lg-0">
                <p className="float-end ">
                  <button type="submit" className="silver_bg px-4 py-3">Submit Your Ideas</button>
                </p>
              </div>

            </div>
          </form>
        </div>
      </div>
    </>
  )
}