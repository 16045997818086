import { useNavigate } from "react-router-dom"

export const Partner = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="row colorcharchol py-3 px-lg-4" id="partners">

        <div className="col-12 col-md-12 m-auto py-5 text-center">
          <h3 className="fw-bold colorcharchol">OUR PARTNERS</h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>
        </div>
        <p className="colorcharchol text-center py-2">
          We are dedicated to providing a wide range of top-notch services that cater to all your business needs. Our team
          of skilled professionals excels in delivering solutions that drive growth and ensure your success in the digital
          landscape.
        </p>



        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4 ">
          <a href="javascript:void(0)"
            onClick={() => window.open('http://nirdpr.org.in/')}
            //  href="http://nirdpr.org.in/"
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/nirdpr-01.png" alt="img not found" className="drops" />
              <p className="card-text1  text-center py-4">C GARD NIRD & PR HYDERABAD</p>
            </div>
          </a>

        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.ey.com/en_in')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/ey-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center py-4">
              ERNST & YOUNG, INDIA</p>
            </div>
          </a>
        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://kpmg.com/xx/en/home.html')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/kpmg_partner-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center py-4">KPMG ADVISORY SERVICES PVT. LTD</p>
            </div>
          </a>
        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.vitalstrategies.org/')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/vital_strategy-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center py-4"> VITAL STRATEGIES</p>
            </div>
          </a>

        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.mars-india.com/')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/mars-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center py-4"> MARS DRIVEN TO EVOLVE</p>
            </div>
          </a>

        </div>
      </div>
    </>
  )
}