import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const HealthCibil =()=>{
    return(
        <>
           <Header active="Products"/>
            <HeroSection/>

            {/* ------------------- */}

            
    <div className="row bgdotimg " id="HealthCibil">
      <div className="col-12 col-md-10 mx-auto py-4">
        <h3 className="fw-bold colorcharchol text-center">Health Cibil</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
      <div className="col-12 col-md-10 mx-auto py-3 text-center">
        <p>
        Health Cibil represents a cutting-edge digital health record system that acts as a centralized hub for all your medical information, ranging from vital signs and laboratory results to prescriptions, medical history, and treatment plans. By consolidating these critical details into one secure online repository, Health Cibil empowers healthcare providers with immediate access to accurate patient data, facilitating well-informed decisions and personalized care delivery. This innovative platform uniquely stores patients' digital health histories online, integrated with Aadhar or mobile numbers, ensuring that medical reports and prescriptions can be effortlessly accessed by clinics, labs, pharmacies, and simplifying the process of obtaining medical claims.
        </p>



      </div>
    </div>
    {/* <!--end of AMS sopi--> */}


    <div className="row py-5 px-3" id="key">
      <div className="col-12 col-md-10 mx-auto py-1">
        <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
 
      <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Comprehensive Medical Repository</b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Real-Time Data Access</b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Integrated Digital Health History</b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Effortless Medical Claims</b></p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p><b>Streamlined Healthcare</b></p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p><b>Enhanced Patient Care</b></p>
        </div>
      </div>
     



    </div>
    {/* <!--end of row key features--> */}


    <div className="row" id="Brochure">
      <div className="col-12 charchole py-5">
        <h4 className="text-center white">
          <b>
            "To acquire further information regarding the product."
          </b>
        </h4>
        <p className="white  text-center py-3">
          Explore the following details and specifications.
        </p>

        <div className="py-4 text-center">
          <a href="http://cdat.co.in/doc/Health_Cibil.pdf" className="shrink-border text-decoration-none"
            target="_blank">Explore Brochure</a>

        </div>
      </div>
    </div>
    {/* <!--end of Brochure row--> */}


            <Footer/>
        </>
    )
}