export const FinancialServicesAdvantages =()=>{
    return(
        <>
            
        <div class="row charchole py-4 mb-5 px-lg-3" id="government_content">
            <div class="col-12 col-md-10 mx-auto py-4">
                <h3 class="fw-bold white text-center">ADVANTAGES</h3>

                <div class="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                <div class=" border border1 text-center px-2">
                    <img src="Assets/Images/Financial_Services/Industry Expertise.png" alt="" style={{width: '120px'}}  class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3"> Industry Expertise</h5>

                    {/* <p>Our content showcases our in-depth knowledge of the financial services sector. This advantage positions us as experts who understand the unique challenges, regulations, and technologies<span id="Edot1">...</span><span id="next1">  within banking, insurance, and financial institutions. It instills confidence in readers that we possess the industry-specific expertise to deliver effective solutions.</span></p>
                    <p onclick="myFunction()" id="myBtne1" class="silver">Read more</p> */}

                    <input type="checkbox" hidden class="read-more-state" id="read-more1" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content showcases our in-depth knowledge of the financial services sector. This advantage positions us as experts who understand the unique challenges, regulations, and technologies
                                <span className="read-more-target ps-2">
                                within banking, insurance, and financial institutions. It instills confidence in readers that we possess the industry-specific expertise to deliver effective solutions.
                                </span>
                            </p>
                        </div>
                        <label for="read-more1" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 white py-2">
                <div class=" border border2 text-center px-2">
                    <img src="Assets/Images/Financial_Services/Secure.png" alt="" style={{width: '120px'}} 
                        class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3">Secure and Reliable Solutions</h5>

                    {/* <p>We emphasize the key advantage of providing secure and reliable software solutions. In the financial services sector, trust and data security are paramount. Our content communicates<span
                            id="Edot2">...</span><span id="next2">  our commitment to developing solutions that prioritize data integrity, encryption, and robust security measures. This feature appeals to financial institutions seeking dependable and resilient software systems.</span>
                    </p>
                    <p onclick="myFunction2()" id="myBtne2" class="silver">Read more</p> */}

                    <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the key advantage of providing secure and reliable software solutions. In the financial services sector, trust and data security are paramount. Our content communicates
                                <span className="read-more-target ps-2">
                                our commitment to developing solutions that prioritize data integrity, encryption, and robust security measures. This feature appeals to financial institutions seeking dependable and resilient software systems.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                <div class=" border border3 text-center px-2">
                    <img src="Assets/Images/Financial_Services/Transactions.png" alt="" style={{width: '120px'}}  class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3">Seamless Transactions</h5>

                    {/* <p>Our content highlights the advantage of enabling seamless transactions within financial services. We emphasize our ability to design solutions that facilitate smooth payment processes,<span
                            id="Edot3">...</span><span id="next3"> quick fund transfers, and efficient transaction management. This feature resonates with organizations looking to enhance customer experiences and optimize operational efficiency.
                            </span></p>
                    <p onclick="myFunction3()" id="myBtne3" class="silver">Read more</p> */}

                    
                    <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content highlights the advantage of enabling seamless transactions within financial services. We emphasize our ability to design solutions that facilitate smooth payment processes,
                                <span className="read-more-target ps-2">
                                quick fund transfers, and efficient transaction management. This feature resonates with organizations looking to enhance customer experiences and optimize operational efficiency.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                <div class=" border border4 text-center px-2">
                    <img src="Assets/Images/Financial_Services/Risk Management.png" alt="" style={{width: '120px'}} class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3">Risk Management</h5>

                    {/* <p>We emphasize our expertise in risk management solutions. This advantage positions us as a partner capable of developing systems that assess and mitigate risks, such as credit risk, market risk, <span
                            id="Edot4">...</span><span id="next4">  and operational risk. By addressing risk factors proactively, we enable financial institutions to make informed decisions and safeguard their financial health.
                            </span>
                    </p>
                    <p onclick="myFunction4()" id="myBtne4" class="silver">Read more</p> */}

                    <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize our expertise in risk management solutions. This advantage positions us as a partner capable of developing systems that assess and mitigate risks, such as credit risk, 
                                <span className="read-more-target ps-2">
                                market  risk, and operational risk. By addressing risk factors proactively, we enable financial institutions to make informed decisions and safeguard their financial health.
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                <div class=" border border5 text-center px-2">
                    <img src="Assets/Images/Financial_Services/Fraud Detection.png" alt="" style={{width: '120px'}}  class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3">Fraud Detection</h5>
                    {/* <p> Our content highlights our capability to develop robust fraud detection mechanisms. This advantage assures readers that we can implement advanced algorithms and machine learning  <span id="Edot5">...</span><span id="next5">
                        techniques to identify and prevent fraudulent activities. This feature resonates with financial institutions seeking to protect their customers and maintain trust in the digital landscape.</span></p>
                    <p onclick="myFunction5()" id="myBtne5" class="silver">Read more</p> */}

                    <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content highlights our capability to develop robust fraud detection mechanisms. This advantage assures readers that we can implement advanced algorithms and machine  
                                <span className="read-more-target ps-2">
                                learning techniques to identify and prevent fraudulent activities. This feature resonates with financial institutions seeking to protect their customers and maintain trust in the digital landscape.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                <div class=" border border6 text-center px-2">
                    <img src="Assets/Images/Financial_Services/regulatory.png" alt="" style={{width: '120px'}} 
                        class="mt-2 ds"/>
                    <h5 class="pt-4 fw-bold pb-3">Regulatory Compliance</h5>
                    {/* <p> We emphasize the advantage of ensuring regulatory compliance in our software solutions. Financial services operate in a highly regulated environment, and our content communicates our 
                        <span id="Edot6">...</span><span id="next6">
                            commitment to developing systems that adhere to industry standards and comply with relevant regulations. This feature positions us as a reliable partner that helps organizations navigate complex compliance requirements.</span>
                    </p>
                    <p onclick="myFunction6()" id="myBtne6" class="silver">Read more</p> */}

                    <input type="checkbox" hidden class="read-more-state" id="read-more6" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the advantage of ensuring regulatory compliance in our software solutions. Financial services operate in a highly regulated environment, and our content communicates our 
                                <span className="read-more-target ps-2">
                                commitment to developing systems that adhere to industry standards and comply with relevant regulations. This feature positions us as a reliable partner that helps organizations navigate complex compliance requirements.
                                </span>
                            </p>
                        </div>
                        <label for="read-more6" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

        </div>
        </>
    )
}