import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Sewerage = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* ---------------------- */}

            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Sewerage</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Navi Mumbai Municipal Corporation (NMMC) has entered into a comprehensive five-year contract for sewerage maintenance in the Navi Mumbai area. To ensure effective oversight of this work, NMMC has established Key Performance Indicators (KPIs) to monitor the daily progress of sewerage maintenance. These KPIs encompass various aspects, including water quality, water pressure, flow, quantity supplied, valve operation, pump house security, housekeeping, and civil, electrical, mechanical, and operational maintenance, all aspects are tracked in real-time and by location. Currently, contractors manually submit monthly progress reports for payment at both the ward and head office levels.

                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}

            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Real-Time KPI Monitoring</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Comprehensive Data</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Water Quality Insights </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Quick Response to Public Grievances</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Efficient Penalty Management</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Streamlined Progress Reporting</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/SEWERAGE_MAITENANCE_BROUCHERS.pdf')}
                        // href="http://new.cdatinfotech.com/doc/SEWERAGE_MAITENANCE_BROUCHERS.pdf"
                         className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>



            <Footer />
        </>
    )
}