import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { WhoWeAreInfo } from "./WhoWeAreInfo"

export const WhoWeAre=()=>{
    return(
        <>
            <Header active="About"/>
            <HeroSection/>
            <WhoWeAreInfo/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}