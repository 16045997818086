import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const MedicineStockManageSystem = () => {
    return (
        <>
         <Header active="Products"/>
            <HeroSection />

            {/* ----------------------------- */}

            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Medicine Stock Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Medicine Stock Management System is a sophisticated application designed to effectively oversee
                        medicine stock management and optimize distribution processes with a centralized database. It offers
                        a range of features, including the creation of category-wise drug masters and ready-made
                        medicine-wise manufacturer and supplier masters. The system allows for the maintenance of
                        supplier-specific L1 data and provides a Supplier-wise Purchase Order Directory. Users can receive
                        materials online from suppliers, complete with Challan details as per the Purchase Order. The
                        application accommodates a hierarchical user structure that aligns with the organization, covering
                        Head Office, Central Store, General Hospital, Pharma Centre, Ward, Department, UPHC, and Covid
                        Centre levels.
                    </p>

                    <p>
                        The system generates monthly MIS reports and includes a Material Archive for tracking expired,
                        stop-use, returned, and collected sample materials. Additionally, the application features QR code
                        functionality via a mobile app for tracking available equipment, ensuring a comprehensive and
                        efficient medicine management process.
                    </p>
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}

            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Efficient Procurement</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Demand Management</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Centralized Distribution</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Challan Generation</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Spot Demand Fulfilment</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Comprehensive Monitoring</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}

            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/MEDICINE_INVENTORY_ERP_BROUCHEERS.pdf')}
                        // href="http://new.cdatinfotech.com/doc/MEDICINE_INVENTORY_ERP_BROUCHEERS.pdf"
                            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}


            <Footer />
        </>
    )
}