import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoGraphicDesign } from "./InfoGraphicDesign"

export const GraphicDesign =()=>{
    return(
        <>
         <Header active="Services"/>
            <HeroSection/>
            <InfoGraphicDesign/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}