export const FriendshipDay =()=>{
    return(
        <>
 <div className="row p-5" id="event_photo_third">

<div className="col-12 col-md-10 mx-auto py-4">
  <h3 className="fw-bold colorcharchol text-center">Friendship Day</h3>

  <div className="zoom-in-out-box2 m-auto">
      <hr/>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/e7.png" className="thumbnail">
        <span>
          <img src="Assets/Images/event/e7.png " alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/e5.png" className="thumbnail">
        <span>
          <img src="Assets/Images/event/e5.png" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/e8.png" className="thumbnail">
        <span>
          <img src="Assets/Images/event/e8.png" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/freinds4-01.jpg" className="thumbnail">
        <span>
          <img src="Assets/Images/event/freinds4-01.jpg" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

</div>
        </>
    )
}