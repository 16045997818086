import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Mrms = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* -------------------------------------------- */}
            <div className="row bgdotimg " id="MRMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Medical Reimbursement Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        MRMS (Medical Reimbursement Management System), a web-based system developed exclusively for Zilla Parishad
                        and Taluka Panchayat Samiti. By providing online requests, comprehensive coverage, computerized tables,
                        document upload facilities, and robust monitoring features, MRMS simplifies the reimbursement process and
                        ensures transparency and accountability.
                    </p>
                    <p>
                        <b>Online Request for Medical Bill:</b> MRMS allows staff members at the Zilla Parishad and Panchayat Samiti
                        levels to make online requests for medical bill reimbursements. This feature eliminates the need for manual
                        paperwork and simplifies the submission process, saving time and effort.
                    </p>

                    <p>
                        <b>Comprehensive Coverage:</b> MRMS covers various aspects of medical expenses, including hospital bills,
                        pathology tests, medicine costs, and living expenses. With MRMS, you can confidently request reimbursement for
                        all relevant medical expenditures.
                    </p>

                </div>
            </div>

            {/* <!--end of MRMS sopi--> */}


            <div className="row py-5 px-3 " id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Computerized Reimbursement Table </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Document Upload Facility</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Monitoring of Medical Reimbursement</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Tracking of Received and Pending Cases </b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Classification of Sanctioned Amounts</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Reconciliation of Medical Expenses</b></p>
                    </div>
                </div>

            </div>

            
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=>window.open('http://cdat.co.in/doc/MEDICAL_REIMBURSEMENT_MANAGEMENT_SYSTEM.pdf')}
                        // href="http://new.cdatinfotech.com/doc/MEDICAL_REIMBURSEMENT_MANAGEMENT_SYSTEM.pdf"
                            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}

            {/* --------------------------------------------- */}
            <Footer />
        </>
    )
}