import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const SmartLaborApplication = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* ---------------------------------------------- */}

            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Smart Labor Application</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        <b>SMART LABOR</b> is a Complete IT Solution for the Employee management that allows HR people, Managers and Business Owners to communicate, operate and monitor the remote labors. The tool is available on mobile, desktop and it’s proposed for those organizations where employees don’t spend their working time in the office.
                    </p>


                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Company Master with Geo Location Integration</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Employee Information Management</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Automated Aadhar Card & PAN Card OCR</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Comprehensive Employee Financial Management</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Attendance Tracking with Geo-Tagging</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Efficient Workflow Management</b></p>
                    </div>
                </div>

            </div>

            {/* <!--end of row key features--> */}

            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/Smart_Labor.pdf')}
                        //  href="http://new.cdatinfotech.com/doc/Smart_Labor.pdf" 
                         class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}


            <Footer />
        </>
    )
}