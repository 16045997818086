import { BrightFutureTogether } from "../BlogPage/BrightFutureTogether"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Sopi = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* -------------------- */}
            <div className="row bgdotimg " id="sopi">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">System of Pension Illustration(SOPI)</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The System of Pension Illustration (SOPI) is a cloud-based web application meticulously designed to
                        administer retirement for pension disbursements for employees within the District and the Primary
                        Education Department. It operates seamlessly across three hierarchical levels Panchayat Samiti,
                        Primary Health Center, and District. The system classNameNameifies pension into four distinct categories:
                        Service Retirement Pension, Family Pension, Voluntary Retirement Pension, Forced Retirement Pension.
                    </p>
                    <p>
                        <b>Auto calculation of Various Amounts:</b> The system incorporates a robust salary calculation that
                        accurately computes service pension amount, gratuity amounts, commutation amounts, and family
                        pension amount.
                    </p>

                    <p>
                        <b> Automated Reports and Order Generation:</b> The Finance Department, situated at the district
                        level, automatically generates payment orders, including Pension Payment Orders (PPO), Commutation
                        Payment Orders (CPO), and Gratuity Payment Orders (GPO), streamlining the disbursement process.
                    </p>

                </div>
            </div>
            {/* -------------------- */}
            <div className="row py-5 px-3 px-lg-5 " id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Employee Data Repository </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Categorization of Pension cases as per type</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Pension Case Tracking </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Automated Reports and Order Generation </b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Auto calculation of Various Amounts </b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Personalized Dashboards</b></p>
                    </div>
                </div>



            </div>

            {/* ---------------------- */}
            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a class="shrink-border text-decoration-none"
                            onClick={() => window.open("http://cdat.co.in/doc/SYSTEM_OF_PENSION_ILLUSTRATION.pdf")}
                            target="_blank" >Explore Brochure</a>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}