import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const WaterSupply = () => {
    return (
        <>
            <Header active="Products"/>
            <HeroSection />

            {/* ---------------------------------- */}

            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Water Supply</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        NMMC DAKSHA presents a web and mobile-based solution to manage the comprehensive Water Supply (O&M) contract
                        awarded by the Navi Mumbai Municipal Corporation (NMMC). It revolves around Key Performance Indicators (KPIs)
                        to facilitate daily contractor progress monitoring. These KPIs cover Water Quality, Water Pressure, Flow,
                        Quantity Supplied, Valve Operation, Pump House Security, House Keeping, and Civil, Electrical, Mechanical &
                        Operational maintenance, all with real-time location tracking.

                    </p>


                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Real-Time KPI Monitoring</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Efficient Documentation</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Comprehensive Dashboard</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Penalty Calculation</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Water Quality Insights</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Simplified Progress Reporting</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=>  window.open('http://cdat.co.in/doc/WATER_SUPPLY_MAITENANCE_BROUCHERS.pdf')}
                        // href="https://cdat.co.in/Doc/Monitoring-of-Garden.pdf"
                         className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}




            <Footer />
        </>
    )
}