export const EnjoyInCompany =()=>{
    return(
        <div className="row p-2 p-lg-5 pt-5 d-flex justify-content-center align-items-center col-lg-11 mx-auto">
        <div className="col-12 pb-5">
          <h2 className="fw-bold text-center py-lg-5">Enjoying the company <span className="fw-lighter silver"> in the company.</span></h2>
        </div>
  
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5">
          <div className="career_img3 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Food Days</h3>
          </div>
        </div>
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5">
          <div className="games career_img1 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Games</h3>
          </div>
        </div>
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5">
          <div className="career_img4 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Parties</h3>
          </div>
        </div>
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5 mt-lg-4">
          <div className="career_img5 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Happy Hour</h3>
          </div>
        </div>
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5 mt-lg-4">
          <div className="career_img6 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Festival</h3>
          </div>
        </div>
  
        <div className="col-12 col-md-6 col-lg-4  p-2 px-lg-5 mt-lg-4">
          <div className="career_img2 d-flex justify-content-center align-items-center">
            <h3 className="text-white">Team Building</h3>
          </div>
        </div>
  
  
      </div>
    )
}