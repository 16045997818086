import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const PoliceGazette = () => {
    return (
        <>
        <Header active="Products"/>

            <HeroSection />
            {/* -------------------- */}



            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Police Gazette Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Police Gazette Management System is a versatile web application designed for efficient employee
                        management. It tracks daily updates, including achievements, punishments, and work assignments, simplifying
                        the creation and updates of Gazette bulletins while automating order generation. The system categorizes data
                        by order type, enhancing organizational clarity and offers status categorization for easy tracking of
                        approved, pending, and in-progress orders, aligned with the financial year.
                    </p>

                    <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of
                        achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a
                        user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative
                        tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p>


                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Employee Performance Tracking</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Timely Gazette Bulletin Creation and Updates</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Order Generation</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Categorization by Status</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p><b>Financial Year Segmentation</b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/Online_Police_Gazette.pdf')}
                        // href="http://new.cdatinfotech.com/doc/Online_Police_Gazette.pdf" 
                        className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}






            <Footer />
        </>
    )
}