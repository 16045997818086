export const Value = () => {
    return (
        <>
           <div className="row charchole bg_value px-lg-3  alignment" id="Value">
                <div className="col-10 col-lg-8 mx-auto py-5  alignment">
                    <h3 className="fw-bold white charchole text-center">Value</h3>

                    <div className="zoom-in-out-box2 mx-auto mb-5">
                        <hr/>
                    </div>

                    <p className="white text-center ">Welcome to CDAT Company, where we deliver innovative software solutions
                        that drive business success. When you choose our software, you unlock a world of benefits and
                        advantages that will transform the way you operate. Here's why our clients choose us:</p>
                </div>

                <div className="col-12 col-md5 col-lg-7  mx-auto text-start px-4 pb-5 pt-3">
                    <p className="silver">Empower Your Business with CDAT Company's Software Solutions</p>
                    <p className="white alignment"><b className="gray">Enhanced Efficiency:</b>Our solutions streamline your processes,
                        automate tasks, and boost productivity, allowing you to achieve more with less effort.</p>

                    <p className="white alignment"><b className="gray">Actionable Insights:</b> Leverage the power of data analytics to gain
                        valuable insights, make informed decisions, and stay ahead of the competition.</p>

                    <p className="white alignment"><b className="gray">Customized Solutions:</b> We understand your unique requirements and
                        deliver tailored software solutions that fit seamlessly into your workflow.</p>

                    <p className="white alignment"><b className="gray">Superior Customer Experience:</b> Our user-centric approach ensures
                        exceptional experiences for your customers, fostering loyalty and satisfaction.</p>

                    <p className="white alignment"><b className="gray">Scalability and Flexibility:</b> Our solutions grow with your business,
                        accommodating expanding needs and enabling future scalability.</p>

                    <p className="white alignment"><b className="gray">Competitive Edge:</b>Stand out in the market with our innovative
                        solutions that give you a strategic advantage and drive business success.
                        Your success is our priority. Let's embark on this journey together and shape the future of your
                        business.</p>
                </div>

                <div className="col-12 col-md-6 col-lg-5 m-auto pb-5 pt-0 mt-5">
                    <img src="Assets/Images/why_3.png" alt="img not found" className="hroimgg ds"/>
                </div>
            </div>
        </>
    )
}