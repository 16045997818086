export const HrmsAdvantages  = () =>{
    return(
        <>
       
       <div className="row charchole py-4 mb-5" id="OBJECTIVES">
            <div className="col-12 col-md-10 mx-auto py-4">
                <h3 className="fw-bold white text-center">OBJECTIVES</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                <div className=" border border1 text-center px-2">
                    <img src="Assets/Images/hrms/decision.png" alt=""  style= {{width: '120px'}}  className="mt-2 ds" />
                    <h5 className="pt-4 fw-bold pb-3"> Enhanced Decision-Making</h5>
                
                    <input type="checkbox" hidden class="read-more-state" id="read-more1" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            e-HRMS enables organizations to make informed decisions at the right time. With comprehensive data and insights readily available, you can strategically plan and allocate resources, ensuring
                                <span className="read-more-target ps-2">
                                optimum productivity and efficiency.
                                </span>
                            </p>
                        </div>
                        <label for="read-more1" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                    
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 white py-2">
                <div className=" border border2 text-center px-2">
                    <img src="Assets/Images/hrms/automation.png" alt=""  style= {{width: '120px'}} 
                        className="mt-2 ds" />
                    <h5 className="pt-4 fw-bold pb-3">Automation for Efficiency</h5>
              

                    <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our HRMS solution focuses on automating manual and repetitive tasks, eliminating human errors, and reducing administrative burdens. By streamlining processes, e-HRMS optimizes workflow,
                                <span className="read-more-target ps-2">
                                enabling your HR team to dedicate more time to critical business activities.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                <div className=" border border3 text-center px-2">
                    <img src="Assets/Images/hrms/transformation.png" alt=""  style= {{width: '120px'}}  className="mt-2 ds"/>
                    <h5 className="pt-4 fw-bold pb-3">Digital Transformation</h5>

                    <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We aim to transform traditional manual service book records into an electronic format through our innovative e-Service Book. This transition preserves essential employee information  
                                <span className="read-more-target ps-2">
                                while creating a searchable database that supports the government's transparency initiatives. Accessible on the internet, it offers a user-friendly interface for both departments and government employees.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                <div className=" border border4 text-center px-2">
                    <img src="Assets/Images/hrms/compliant.png" alt=""  style= {{width: '120px'}} 
                        className="mt-2 ds"/>
                    <h5 className="pt-4 fw-bold pb-3">Compliance with RTI Act</h5>

                    <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            e-HRMS aligns with the objectives of the Right to Information (RTI) Act regarding employee postings and transfers. By eliminating redundant paperwork and establishing a centralized
                                <span className="read-more-target ps-2">
                                system, our solution ensures transparency, simplifies processes, and promotes efficiency across various levels.
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                <div className=" border border5 text-center px-2">
                    <img src="Assets/Images/hrms/Accurate Record Keeping.png" alt="" style= {{width: '120px'}} className="mt-2 ds"/>
                    <h5 className="pt-4 fw-bold pb-3">Consistent and Accurate Record Keeping</h5>

                    <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            With e-HRMS, bid farewell to duplicate and inconsistent record keeping. By consolidating employee data in a single platform, the system maintains accurate records, enhancing data  
                                <span className="read-more-target ps-2">
                                integrity and reliability throughout the employee lifecycle.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                <div className=" border border6 text-center px-2">
                    <img src="Assets/Images/hrms/solution.png" alt="" style= {{width: '120px' }}
                        className="mt-2 ds"/>
                    <h5 className="pt-4 fw-bold pb-3">Rapid Grievance Resolution</h5>

                    <input type="checkbox" hidden class="read-more-state" id="read-more6" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            e-HRMS enables prompt response to employee grievances related to promotions, transfers, and postings. By providing a streamlined and transparent process, our solution promotes
                                <span className="read-more-target ps-2">
                                employee satisfaction, fostering a positive work environment.
                                </span>
                            </p>
                        </div>
                        <label for="read-more6" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                </div>
            </div>

        </div>
        </>
    )

}