import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const PublicGrievance = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* --------------------------------- */}
            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Public Grievance Management
                        System</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-5 mx-auto py-3 text-center">
                    <p class="alignment">
                        Grievance redressal is now conceptualized as a comprehensive system. Grievances can be filed under
                        appropriate category. Multiple modes for receiving complaints. Software and Mobile app provide for
                        online processing of grievances. Monitoring and alert mechanism built in the system. Citizen can
                        track and see Grievance in real time Processing of Grievances. All grievances received from all
                        sources are integrated into one portal. Provision of online forwarding with full automated workflow.
                        Segregation of grievance as per the timelines for e.g., some of grievances which will require notice
                        period to be served is having long resolution time (Time Lines Required). Once grievance is
                        submitted, it is automatically assigned to the concerned Zone officer.
                    </p>

                </div>

                <div class=" col-12 col-md-6 text-start mx-auto py-5">

                    <h6><b>Escalation of grievance automatically through pre-defined workflow </b></h6>
                    <p><b>Level 1 –</b> Concerned Officer (Zone Office i.e., Assistant Commissioner)</p>
                    <p><b>Level 2 –</b> HoD of the respective Department </p>
                    <p><b>Level 3 –</b> Additional Municipal Commissioner </p>
                    <p><b>Level 4 –</b> Municipal Commissioner</p>
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Categorized Grievance Filing</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Multiple Complaint Submission Channels</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Online Processing</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Monitoring and Alert System</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Real-Time Tracking</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Automated Workflow</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/Public_Grievance.pdf')} 
                        // href="http://new.cdatinfotech.com/doc/Public_Grievance.pdf"
                         class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}