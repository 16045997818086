export const CultureInfo =()=>{
    return(
        <>
                   <div className="row" id="culture">
            <div className="col-10 mx-auto py-4">
                <h3 className="fw-bold colorcharchol text-center">CULTURE</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div className="col-12 col-md-10 text-center mx-auto py-3 px-lg-5  alignment">

                <p>
                    At CDAT Company, we embody a set of core values that drive our work philosophy and commitment to
                    delivering high-quality software products. With a positive and attractive approach, we are dedicated
                    to helping businesses achieve their goals and unlock their full potential.
                </p>
                <p>
                    At CDAT Company, we are passionate about empowering your success. Through our values, work
                    philosophy, and commitment to delivering high-quality software products, we aim to make a positive
                    and lasting impact on your business. Experience the difference with CDAT Company and let us help you
                    unlock your true potential.
                </p>
            </div>
        </div>


        <div className="row " id="cultureinfo">
            <div className="col-12 col-md-10 text-start m-auto bgdotimg">
                <div className="row">

                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/inovation.png" alt="" style={{width:'20%'}}className="ds" data-aos="fade-right"  />
                        <h4 data-aos="fade-right"   className="py-3">
                            <b>Innovation</b></h4>
                        <p data-aos="fade-right"  >
                            We thrive on innovation, constantly exploring new technologies and industry trends. By
                            staying at the forefront of advancements, we bring fresh ideas and cutting-edge solutions to
                            our clients, enabling them to stay ahead in the competitive landscape.
                        </p>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-10 text-end m-auto ">
                <div className="row text-end">
                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/customer.png" alt="" style={{width:'20%'}} className="ds" data-aos="fade-left" />
                        <h4 className="py-3" data-aos="fade-left" >
                           <b> Customer Centric Approach</b>
                        </h4>
                        <p data-aos="fade-left" >
                            Our customers are at the heart of everything we do. We listen attentively to their needs,
                            understand their challenges, and strive to provide tailored solutions that address their
                            specific requirements. Your success is our success, and we are committed to exceeding your
                            expectations.
                        </p>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-10 text-start m-auto bgdotimg">
                <div className="row">

                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/exxelence.png" alt="" style={{width:'20%'}} className="ds" data-aos="fade-right"/>
                        <h4 data-aos="fade-right" className="py-3">
                            <b>Quality and Excellence</b>
                        </h4>
                        <p data-aos="fade-right">
                            We have a relentless pursuit of quality and excellence in all aspects of our work. From the initial concept to the final product, we adhere to rigorous standards and meticulous attention to detail. Our aim is to deliver software solutions that are reliable, scalable, user-friendly, and built to withstand the test of time.
                        </p>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-10 text-end m-auto ">
                <div className="row text-end">
                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/collaboration.png" alt="" style={{width:'20%'}}className="ds" data-aos="fade-left"/>
                        <h4 className="py-3" data-aos="fade-left">
                           <b> Collaborative Partnership</b>
                        </h4>
                        <p data-aos="fade-left">
                            We believe in fostering strong partnerships with our clients. We value open and transparent communication, encouraging collaboration and active involvement throughout the development process. Together, we work as a team to create solutions that truly meet your business objectives.
                        </p>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-10 text-start m-auto bgdotimg">
                <div className="row">

                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/continuous-improvement.png" alt="" style={{width:'20%'}} className="ds" data-aos="fade-right"/>
                        <h4 data-aos="fade-right"className="py-3" >
                        
                           <b> Continuous Improvement</b>
                        </h4>
                        <p data-aos="fade-right">
                            Our commitment to delivering high-quality software goes hand in hand with our dedication to continuous improvement. We embrace feedback, embrace emerging technologies, and embrace a culture of learning. By constantly refining our processes and expanding our knowledge, we ensure that we always deliver the best solutions to our clients.
                        </p>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-10 text-end m-auto ">
                <div className="row text-end">
                    <div className="col-12 col-md-10 py-2 mx-auto">
                        <img src="Assets/Images/culture/star.png" alt="" style={{width:'20%'}} className="ds" data-aos="fade-left"/>
                        <h4 className="py-3" data-aos="fade-left">
                         <b>   Positive and Dynamic Culture</b>
                        </h4>
                        <p data-aos="fade-left">
                            Our team thrives in a positive and dynamic work environment. We foster a culture that promotes creativity, encourages innovation, and embraces diversity. With a passionate and talented team, we bring enthusiasm, energy, and a fresh perspective to every project we undertake.
                        </p>
                    </div>

                </div>
            </div>

        </div>
        </>
    )
}