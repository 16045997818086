import { Navigate, useNavigate } from "react-router-dom"


export const Footer = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="row " id="footer">
                <footer className="footer-06 charchole">
                    <div className="row pt-4 px-5 " id="footer">
                        <div className="col-12 col-md-8 ">
                            <div className="row">
                                <div className="col-md-4 mb-md-0 mb-4">
                                    <h2 className="footer-heading text-white">Services</h2>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/BiSolutions?ScreenName=Business Intelligence Solutions&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right "></i> BI Solutions
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/DataAnalytics?ScreenName=Data Analytics&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Data Analytics
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Web Development
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Mobile Development
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/CustomizedSolution?ScreenName=Customized Solutions&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Customized Solutions
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/ProductDevelopment?ScreenName=Product Development&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Product Development
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/ProductMaintenance?ScreenName=Product Maintenance&Name=Services')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Product Maintenance
                                            </a>
                                        </li>

                                    </ul>
                                </div>

                                <div className="col-md-4 mb-md-0 mb-4">
                                    <h2 className="footer-heading text-white">Industry Expertise</h2>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/Egovernance?ScreenName=E-Governance&Name=Expertise')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> E-Governance
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/Healthcare?ScreenName=Healthcare&Name=Expertise')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Healthcare
                                            </a>
                                        </li>

                                        <li>
                                        <a href="javascript:void(0)"
                                                    onClick={() => navigate('/FinancialServices?ScreenName=Financial Services&Name=Expertise')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Financial Services
                                            </a>
                                        </li>

                                        <li>
                                        <a href="javascript:void(0)"
                                                    onClick={() => navigate('/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry&Name=Expertise')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Manufacturing & Services Industry
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)"
                                                onClick={() => navigate('/E_Commerce?ScreenName=E-Commerce&Name=Expertise')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> E-Commerce
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => navigate('/HRMS?ScreenName=Human Resource Management System&Name=Expertise')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> HRMS
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Agriculture?ScreenName=Agriculture&Name=Expertise')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Agriculture
                                            </a>
                                        </li>

                                    </ul>
                                </div>

                                <div className="col-md-4 mb-md-0 mb-4">
                                    <h2 className="footer-heading text-white ">Important Links</h2>
                                    <ul className="list-unstyled">
                                        <li>
                                        <a href="javascript:void(0)"
                                                    onClick={() => navigate('/WhoWeAre?ScreenName=Who We Are&Name=About')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> About Us
                                                
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/Products?ScreenName=Products')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Products
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/CareerPage?ScreenName=Careers')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i
                                                    className="fa-solid fa-caret-right"></i> Careers
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                                    onClick={() => navigate('/OurClients?ScreenName=Our Clients&Name=Clients')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Clients
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/Blog?ScreenName=Blogs')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Blogs
                                            </a>
                                        </li>
                                        <li>
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/ContactPage?ScreenName=Contact')} 
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Contact Us
                                            </a>
                                        </li>
                                        <li>

                                        {/* <a href="javascript:void(0)"
                                                    onClick={() => navigate('/WhoWeAre?ScreenName=Who We Are&Name=About')} className="text-decoration-none ">Who We Are</a> */}


                                        <a href="javascript:void(0)"
                                        onClick={()=> navigate('/Sitemap?ScreenName=Sitemap')}
                                                className="py-1 d-block ahover text-decoration-none">
                                                <i className="fa-solid fa-caret-right"></i> Sitemap
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 text-center">
                            <div className="py-2">
                                <a href="javascript:void(0)"  onClick={() => navigate('/')}><img src="Assets/Images/logo_color-01.png" alt="image not found"
                                    style={{ width: "90px", height: "45px" }} /></a>
                            </div>
                            <p className="ahover  alignment">At CDAT, we are a leading software company dedicated to shaping your digital
                                journey. With
                                a passion for innovation and a commitment to excellence, we deliver cutting-edge solutions that drive your
                                business forward.</p>
                            <div className="social_icon py-2 col-12">
                                <a href="https://www.linkedin.com/company/cdattechnologies/?trk=public_profile_topcard-current-company"
                                    target="_blank" className="mx-2"><img src="Assets/Images/social/linkedin.png" alt="" /></a>

                                <a href="https://www.instagram.com/cdatpune/?igshid=ZDc4ODBmNjlmNQ%3D%3D" target="_blank"
                                    className="mx-2"><img src="Assets/Images/social/instagram.png" alt="" /></a>

                                <a href="https://wa.me/919172650917" target="_blank" className="mx-2"><img src="Assets/Images/social/whatsapp.png"
                                    alt="" /></a>

                                <a href="https://www.facebook.com/people/CDAT-PUNE/100053712026296/?mibextid=ZbWKwL" target="_blank"
                                    className="mx-2"><img src="Assets/Images/social/facebook.png" alt="" /></a>

                                <a href="tel:+919172650917" target="_blank" className="mx-2"><img src="Assets/Images/social/call.png" alt="" /></a>
                            </div>

                            <div className="privacy_policy py-2">
                                <a onClick={() => navigate('/PrivacyPolicy?ScreenName=Privacy Policy')}
                                    // href="Privacy_Policy.html"
                                    className="ahover text-decoration-none cursor_pointer"> Privacy Policy </a>
                                <span className="white"> | </span>
                                <a onClick={() => navigate('/TermsConditions?ScreenName=Terms & Conditions')}
                                    target="_blank" className="ahover text-decoration-none cursor_pointer">Terms & Conditions
                                </a>
                            </div>

                        </div>
                        <hr className="text-white" style={{ width: "100%;" }} />

                        <div className="col-12 text-center  py-3">
                            <p className="white text-center">
                                &copy;
                                <script type="text/javascript">document.write(new Date().getFullYear());</script> Copyright <span
                                    className="silver">CDAT</span> Pune.
                                All
                                Rights Reserved.
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}