export const InfoGraphicDesign = () => {
    return (
        <>


            <div className="row bgdotimg " id="Graphic_Design_content">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Graphic Design</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Our talented team of creative professionals is passionate about helping our clients achieve their branding and design goals.
                    </p>
                    <p>
                        At CDAT, we are passionate about creating designs that make a difference. Whether you're a startup looking to establish your brand or an established business seeking a design refresh, we are here to bring your vision to life.
                    </p>

                </div>
            </div>
            {/* <!--end of row Graphic_Design_content--> */}

            <div className="row" id="Graphic_Design_info">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Our Graphic Design Services</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/graphic_design/brand.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Brand Identity Design</b></h4>

                    <p className="p-2 pb-lg-5 ">We specialize in crafting unique and impactful brand identities that make a lasting impression. From designing captivating logos to selecting the perfect color palettes and typography, we create cohesive visual systems that reflect the essence of your brand.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/graphic_design/Print Design.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Print Design Services</b></h4>

                    <p className="p-2 pb-lg-4">Our expertise extends to designing a wide range of print materials that capture attention and drive results. Whether you need eye-catching brochures, stylish business cards, or compelling posters, we bring your ideas to life with exceptional design and meticulous attention to detail.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/graphic_design/Print Design.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="img/graphic_design/web_design.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Web and Digital Design</b></h4>

                    <p className="p-2 pb-lg-5 ">In the digital realm, we excel at creating engaging and user-friendly designs for websites, mobile applications, and digital marketing campaigns. Our designs are crafted to enhance user experience, promote interaction, and ultimately drive conversions.
                    </p>
                </div>
            </div>

            {/* ------------------------------------------------------ */}

            <div className="row services_shadow lightblue mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/graphic_design/UIUX Design.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>UI/UX Design</b></h4>

                    <p className="p-2 pb-lg-4">We understand the importance of intuitive and seamless user experiences. Our UI/UX design services are tailored to optimize the usability and functionality of your digital products, ensuring that your customers have an enjoyable and memorable interaction with your brand.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/graphic_design/UIUX Design.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>



            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/graphic_design/illustration.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Illustration and Infographics</b></h4>

                    <p className="p-2 pb-lg-5 ">Our skilled illustrators create captivating illustrations and infographics that communicate complex ideas in a visually compelling way. These custom-designed visuals are perfect for presentations, marketing materials, and online content, helping you stand out from the crowd.
                    </p>
                </div>
            </div>

            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/graphic_design/motion-graphic.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Motion Graphics and Animation</b></h4>

                    <p className="p-2 pb-lg-4">We have a dedicated team of motion graphic designers who specialize in creating dynamic animations and videos. Whether you need a captivating explainer video or engaging social media animations, we bring your ideas to life with seamless motion and stunning visual effects.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/graphic_design/motion-graphic.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>

            <div className="row services_shadow orange mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/graphic_design/Tailored Solutions.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Tailored Solutions</b></h4>

                    <p className="p-2 pb-lg-5 ">We understand that every client is unique, and we take a personalized approach to each project. Our design solutions are customized to meet your specific needs and objectives, resulting in designs that are truly reflective of your brand's personality and values.brand.
                    </p>
                </div>
            </div>



            <div className="row services_shadow green mx-auto my-4 sky">
                <div className="col-12 col-md-4 text-center d-block d-md-none  mx-auto ss">
                    <img src="Assets/Images/graphic_design/professional-development.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 border px-2 py-3 px-lg-5  mx-auto">
                    <h4 className="pt-3  px-2"><b>Timely Delivery and Professionalism</b></h4>

                    <p className="p-2 pb-lg-4">We pride ourselves on delivering high-quality designs within agreed-upon timelines. Our professional team is committed to providing exceptional customer service and ensuring a smooth and transparent design experience from start to finish.
                    </p>
                </div>

                <div className="col-12 col-md-4 text-center d-none d-md-block pt-3 ss mx-auto">
                    <img src="Assets/Images/graphic_design/professional-development.png" alt="" srcset="" style={{ width: '50%' }} />
                </div>
            </div>



            <div className="row services_shadow pink mx-auto my-4">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ss bgimg">
                    <img src="Assets/Images/graphic_design/Cutting-Edge Tools and Trends.png" alt="img not found" srcset="" style={{ width: '50%' }} />
                </div>

                <div className="col-12 col-md-8 pt-3 px-2   px-lg-5 text-right ">
                    <h4 className="pt-3 charcholecolor px-2 "><b>Cutting-Edge Tools and Trends</b></h4>

                    <p className="p-2 pb-lg-5 ">Our designers are equipped with the latest industry tools and stay abreast of current design trends. By blending creativity with technological expertise, we deliver designs that are modern, innovative, and aligned with the ever-evolving demands of the digital landscape.
                    </p>
                </div>
            </div>




        </>
    )
}