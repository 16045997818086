import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const PTMS = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />
            {/* ---------------------------- */}

            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Project Tracking and Monitoring System</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Project Tracking & Monitoring System (PTMS) developed for achieving the real time project reporting in
                        municipal corporation area. The System will help to capture the real time project progress data, analysis of
                        the real data and provide different reports at HOD & Commissioner level for the decision making. It includes
                        features such as department-specific project management, electoral ward support. PTMS covers various funding
                        projects, ensures role-based data access, and facilitates milestone tracking with geo-tagging.
                    </p>

                    <p>
                        This comprehensive system offers user-friendly data entry, graphical dashboards, and notification alerts for
                        efficient project management. It also handles test report management, and budget analysis. PTMS's dynamic
                        dashboard and project status display enhance transparency and decision-making. Overall, PTMS underscores the
                        municipality's commitment to data-driven project efficiency and transparency.
                    </p>


                </div>
            </div>
            {/* <!--end of AMS sopi--> */}

            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Project/Work Management as per Electoral Ward</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Real-Time Progress Monitoring </b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Project wise Milestone Tracking with Geo-Tagging</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Spill over Tracking </b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p><b>Project Work Sanction Details</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p><b>Work Estimate Provision and Budget Analysis</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}

            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/Project_Tracking_and_Monitoring_System.pdf')}
                        //  href="http://new.cdatinfotech.com/doc/Project_Tracking_and_Monitoring_System.pdf"
                            class="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}