export const IndependenceDay = () => {
    return (
        <>
            <div class="row p-5" id="event_photo_six">

                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Independence Day</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class=" col-12 col-md-6 col-lg-3 ds">
                    <div class="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/independence_day1-01.jpg" class="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/independence_day1-01.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class=" col-12 col-md-6 col-lg-3 ds">
                    <div class="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/independence_day2-01.jpg" class="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/independence_day2-01.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class=" col-12 col-md-6 col-lg-3 ds">
                    <div class="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/independence_day3-01.jpg" class="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/independence_day3-01.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class=" col-12 col-md-6 col-lg-3 ds">
                    <div class="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/independence_day4-01.jpg" class="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/independence_day4-01.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}