export const AdvantagesOfMSI = () => {
    return (
        <>

            <div className="row charchole py-4 mb-5 px-lg-3" id="government_content">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold white text-center">ADVANTAGES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border1 text-center px-2">
                        <img src="Assets/Images/Manufacturing/Industry-Focused Solutions.png" alt="" style={{ width: '120px' }} className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Industry-Focused Solutions</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more1" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                Our software solutions are tailored specifically to meet the unique demands of the manufacturing and service industries. We comprehend the intricacies of these sectors, ensuring our
                                <span className="read-more-target ps-2">
                                    products align perfectly with your business needs.
                                </span>
                            </p>
                        </div>
                        <label for="read-more1" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2">
                    <div className=" border border2 text-center px-2">
                        <img src="Assets/Images/Manufacturing/Enhanced Efficiency.png" alt="" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Enhanced Efficiency</h5>
                     
                        <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                                Our software solutions are tailored specifically to meet the unique demands of the manufacturing and service industries. We comprehend the intricacies of these sectors, ensuring our
                                <span className="read-more-target ps-2">
                                improved productivity.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div className=" border border3 text-center px-2">
                        <img src="Assets/Images/Manufacturing/decision-making.png" alt="" style={{ width: '120px' }} className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Data-Driven Decision Making</h5>
                     
                        <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Harness the power of data with our advanced analytics and reporting tools. Make informed decisions based on real-time insights, allowing you to stay ahead of
                                <span className="read-more-target ps-2">
                                the competition.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 mx-auto">
                    <div className=" border border4 text-center px-2">
                        <img src="Assets/Images/Manufacturing/Scalability.png" alt="" style={{ width: '120px' }}
                            className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Scalability</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Whether you're a small enterprise or a large corporation, our software solutions are designed to scale seamlessly with your growth. You can trust us to support your evolving 
                                <span className="read-more-target ps-2">
                                business needs.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 white py-2 mx-auto">
                    <div className=" border border5 text-center px-2">
                        <img src="Assets/Images/Manufacturing/cyber-security.png" alt="" style={{ width: '120px' }} className="mt-2 ds" />
                        <h5 className="pt-4 fw-bold pb-3">Cybersecurity</h5>

                        <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Protecting your data and assets is paramount. Our software incorporates robust cybersecurity measures to safeguard your sensitive information and maintain the trust of 
                                <span className="read-more-target ps-2">
                                your clients. 
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                    </div>
                </div>


            </div>
        </>
    )
}