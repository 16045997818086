export const HrmsInfo = () => {
    return (
        <>
            <div className="row bgdotimg " id="Human Resource Management System">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Human Resource Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr/>
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                    Welcome to our e-HRMS (Electronic Human Resource Management System) application, a cutting-edge solution designed to cater specifically to the needs of big organizations. Our solutions empower financial institutions to stay competitive in a rapidly evolving industry. Our mission is to empower your organization to make accurate and timely decisions, ensure efficient monitoring, streamline manpower planning, optimize recruitment processes, facilitate seamless postings, promotions, and transfers based on employee skill sets.    </p>

                </div>
            </div>
        </>
    )
}