export const WhoWeAreInfo = () => {
    return (
        
        <>
            <div className="row" id="who_we_are_info">
                <div className="col-12 col-md-10 text-center m-auto py-5 colorcharchol">
                    <h2>
                        <b>
                            Empowering Businesses with Customized Solutions, Trusted by Government and Private Clients.
                        </b>
                    </h2>
                </div>


                <div className="col-12 px-5  pb-5 bgdotimg  alignment">
                    <p>
                        Welcome to CDAT Company, a trusted provider of software solutions since our establishment in 2014.
                        Our journey began with a strong focus on delivering customized software solutions tailored to meet
                        the unique needs of our clients. We take pride in our ability to understand the intricate
                        requirements of different industries and design solutions that drive efficiency, productivity, and
                        growth. Our expertise has led us to forge successful partnerships with government clients, who have
                        remained satisfied with customers throughout the years.
                    </p>
                    <p>
                        Serving the public sector has been a significant achievement for us, as we recognized the importance
                        of supporting government entities with robust and reliable software. Our products have been
                        successfully deployed in various government departments, enabling streamlined operations, improved
                        service delivery, and the achievement of organizational goals.
                    </p>
                    <p>
                        The continued trust and satisfaction of our government customers validate the quality and
                        effectiveness of our solutions. Their unwavering support motivates us to maintain our commitment to
                        excellence and customer satisfaction.
                    </p>

                    <p>
                        At CDAT Company, we are driven by innovation and the belief that technology can transform businesses
                        of all sizes. Our team of skilled professionals combines deep domain knowledge with technical
                        expertise to closely collaborate with clients. We understand your unique requirements and develop
                        customized solutions that align with your goals and vision. Whether you need a comprehensive
                        software suite or specific modules tailored to your industry, we have the expertise to exceed your
                        expectations. Our commitment extends beyond the initial deployment, as we provide ongoing support,
                        updates, and maintenance to ensure seamless operation and adaptability to your evolving needs.
                    </p>

                    <p>
                        Looking ahead, we remain dedicated to driving innovation, staying at the forefront of technology
                        advancements, and continuously enhancing our offerings. Our passion for delivering top-notch
                        software solutions, combined with the trust and satisfaction of our valued clients, fuels our
                        pursuit of excellence.
                    </p>

                    <p>
                        Join the ranks of our satisfied customers and experience the power of customized software solutions.
                        Contact us today to discuss how CDAT Company can transform your business and set you on the path to
                        long-term success.
                    </p>
                </div>
            </div>
        </>
    )
}