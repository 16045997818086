export const HeroSection = () =>{
    return(
        <>
 <div className="row herobg2 charchole g-0 " id="herosection">


{/* <video className="img-fluid" autoplay loop muted>
  <source  type="video/mp4" / >
</video> */}
<video src="Assets/Images/herosection_video.mp4" autoPlay loop muted></video>



</div>
        </>
    )
}