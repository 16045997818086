import { useNavigate } from "react-router-dom"

export const CallToAction = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="row " id="call-to-action">

                <div className="col-12 col-md-6 text-center py-4 bgwhite callaction">
                    <h2 className="mt-2 mb-2 callh2">Call To Action</h2>

                    <p className="text-center py-3">Get in touch with CDAT Company today and experience the difference our software
                        solutions can make for your business. Together, we can drive innovation, efficiency, and growth.</p>
                </div>
                <div className="col-12 col-md-6 charchole white text-center py-4 callaction1">
                    <h2 className="px-lg-5">Ready to Take Your Business to the Next Level?</h2>
                    <p className="text-center">Contact CDAT Company Today!</p>
                    <div className="py-4">
                    <a href="javascript:void(0)" onClick={() => navigate('/ContactPage?ScreenName=Contact')}  className="shrink-border text-decoration-none">Connect</a>
                    </div>
                </div>
            </div>
        </>
    )
}