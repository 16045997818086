export const E_CommerceAdvantages = () => {
    return (
        <>

            <div class="row charchole py-4 mb-5" id="government_content">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold white text-center">ADVANTAGES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border1 text-center px-2">
                        <img src="Assets/Images/E-Commerce/Industry Expertise.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3"> Industry Expertise</h5>
                  

                        <input type="checkbox" hidden class="read-more-state" id="read-more1" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content highlights our expertise in the retail and e-commerce landscape. This advantage positions us as knowledgeable professionals who understand the unique challenges, trends, and
                                <span className="read-more-target ps-2">
                                dynamics of the industry. It instills confidence in readers that we possess the industry-specific knowledge to provide effective solutions.
                                </span>
                            </p>
                        </div>
                        <label for="read-more1" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2">
                    <div class=" border border2 text-center px-2">
                        <img src="Assets/Images/E-Commerce/adaptation.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Adaptation to Evolving Landscape</h5>
                   
                        <input type="checkbox" hidden class="read-more-state" id="read-more2" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the advantage of helping businesses stay ahead of the curve in the ever-evolving retail and e-commerce landscape. Our content communicates our ability to navigate
                                <span className="read-more-target ps-2">
                                changing consumer behaviors, emerging technologies, and market trends. This feature positions us as a partner that can assist retailers in embracing innovation and driving growth.
                                </span>
                            </p>
                        </div>
                        <label for="read-more2" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border3 text-center px-2">
                        <img src="Assets/Images/E-Commerce/Inventory Management e.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Inventory Management</h5>
                     <input type="checkbox" hidden class="read-more-state" id="read-more3" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We focus on the advantage of effective inventory management through our solutions. Our content highlights our ability to streamline inventory processes, optimize stock levels, and 
                                <span className="read-more-target ps-2">
                                prevent stockouts or overstocking. This advantage enables retailers to maintain the right balance, reduce costs, and provide seamless shopping experiences to customers.
                                </span>
                            </p>
                        </div>
                        <label for="read-more3" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border4 text-center px-2">
                        <img src="Assets/Images/E-Commerce/Retailing.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Omni-Channel Retailing</h5>

                      

                        <input type="checkbox" hidden class="read-more-state" id="read-more4" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            Our content emphasizes the advantage of enabling omni-channel retailing. We communicate our proficiency in integrating physical stores, online platforms, and mobile 
                                <span className="read-more-target ps-2">
                                applications to create a cohesive and consistent shopping experience. This feature helps retailers reach customers through various channels, drive customer loyalty, and enhance sales opportunities.
                                </span>
                            </p>
                        </div>
                        <label for="read-more4" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border5 text-center px-2">
                        <img src="Assets/Images/E-Commerce/Personalized Customer Experiences.png" alt="" style={{ width: '120px' }} class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Personalized Customer Experiences</h5>
         

                        <input type="checkbox" hidden class="read-more-state" id="read-more5" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We highlight the advantage of delivering personalized customer experiences. Our content showcases our ability to leverage customer data, advanced analytics, and targeted marketing
                                <span className="read-more-target ps-2">
                                strategies to create tailored experiences. This advantage enables retailers to build strong customer relationships, improve customer satisfaction, and drive repeat business.
                                </span>
                            </p>
                        </div>
                        <label for="read-more5" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 white py-2 ">
                    <div class=" border border6 text-center px-2">
                        <img src="Assets/Images/E-Commerce/Secure Online Transactions.png" alt="" style={{ width: '120px' }}
                            class="mt-2 ds" />
                        <h5 class="pt-4 fw-bold pb-3">Secure Online Transactions</h5>
              

                        <input type="checkbox" hidden class="read-more-state" id="read-more6" />
                        <div className="read-more-wrap">
                            <p className="p1  text-white">
                            We emphasize the advantage of secure online transactions through our solutions. Our content communicates our commitment to implementing robust security measures to protect
                                <span className="read-more-target ps-2">
                                customer payment information and ensure safe online transactions. This feature reassures customers that their sensitive data is safeguarded, enhancing trust and confidence in the retail and e-commerce experience.
                                </span>
                            </p>
                        </div>
                        <label for="read-more6" className="read-more-trigger_closed pb-4  pointer">Read More...</label>
                        <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                    </div>
                </div>

            </div>
        </>
    )
}