import { useNavigate } from "react-router-dom"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { HeroSection } from "./HeroSection"

export const Sitemap = () => {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <HeroSection />

            {/* ----------------------- */}

            <div className="row p-3" id="sitemap_info">

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole">
                        <div className="card-body charchole">
                            <h3 className="white"><b>About us</b></h3>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WhoWeAre?ScreenName=Who We Are&Name=About')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Who We Are
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WhatWeDo?ScreenName=What We Do&Name=About')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> What We Do
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WhyUs?ScreenName=Why Us&Name=About')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Why Us
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Culture?ScreenName=Culture&Name=About')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Culture Industry
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Events?ScreenName=Events&Name=About')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Events
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole">
                        <div className="card-body charchole">
                            <h3 className="white"><b>Industry Expertise</b></h3>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Egovernance?ScreenName=E-Governance&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> E-Governance
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Healthcare?ScreenName=Healthcare&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Healthcare
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/FinancialServices?ScreenName=Financial Services&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Financial Services
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Manufacturing & Services <br />
                                        Industry
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/E_Commerce?ScreenName=E-Commerce&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> E-Commerce
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => navigate('/HRMS?ScreenName=Human Resource Management System&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> HRMS
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Agriculture?ScreenName=Agriculture&Name=Expertise')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Agriculture
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">

                            <h3 className="white"><b>Services</b></h3>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/BiSolutions?ScreenName=Business Intelligence Solutions&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> BI Solutions
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/DataAnalytics?ScreenName=Data Analytics&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Data Analytics
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none"><i
                                            className="fa-solid fa-caret-right"></i> Web Development
                                    </a></li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/GraphicDesign?ScreenName=Graphic Design&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Graphic Design
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Mobile Development
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/CustomizedSolution?ScreenName=Customized Solutions&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Customized Solutions
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ProductDevelopment?ScreenName=Product Development&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Product Development
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ProductMaintenance?ScreenName=Product Maintenance&Name=Services')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Product Maintenance
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">
                            <h3 className="white"><b>Blogs</b></h3>

                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Blog?ScreenName=Blogs')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Blogs
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">
                            <h3 className="white"><b>Careers</b></h3>

                            <ul className="list-unstyled">
                                <li>  <a href="javascript:void(0)"
                                    onClick={() => navigate('/CareerPage?ScreenName=Careers')}
                                    className="py-1 d-block ahover text-decoration-none">
                                    <i
                                        className="fa-solid fa-caret-right"></i> Careers
                                </a>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">
                            <h3 className="white"><b>Contact Us</b></h3>

                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ContactPage?ScreenName=Contact')}
                                        className="py-1 d-block ahover text-decoration-none">
                                        <i
                                            className="fa-solid fa-caret-right"></i> Contact Us
                                    </a>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">
                            <p>
                                <h3 className="white"><b>Products</b></h3>

                                <input type="checkbox" hidden className="read-more-state" id="read-more" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-white">
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')}
                                                    className="py-1 d-block ahover text-decoration-none">
                                                    <i
                                                        className="fa-solid fa-caret-right"></i> System of Pension Illustration
                                                    -(SOPI)
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')}
                                                    className="py-1 d-block ahover text-decoration-none">
                                                    <i className="fa-solid fa-caret-right"></i> Medical
                                                    Reimbursement Management
                                                    System - (MRMS)
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')}
                                                    className="py-1 d-block ahover text-decoration-none">
                                                    <i
                                                        className="fa-solid fa-caret-right"></i> Asset Management System -
                                                    (AMS)
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')}
                                                    className="py-1 d-block ahover text-decoration-none">
                                                    <i
                                                        className="fa-solid fa-caret-right"></i> Letter & File Management System
                                                    (LFMS)

                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')}
                                                    className="py-1 d-block ahover text-decoration-none">
                                                    <i
                                                        className="fa-solid fa-caret-right"></i>  Online Letter System (e-Tapal)

                                                </a>
                                            </li>

                                            <span className="read-more-target ps-2">
                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/MedicineStockManageSystem?ScreenName=Medicine Stock Management System&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none"><i
                                                            className="fa-solid fa-caret-right"></i> Medicine Stock Management System

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/PublicGrievance?ScreenName=Public Grievance Management System&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i className="fa-solid fa-caret-right"></i> Public Grievance Management
                                                        System

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i> Smart Labor Application

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i> Monitoring of Sewerage
                                                        (O/M) Work

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i> Monitoring of Road
                                                        (O/M) Work

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i>  Monitoring of STP
                                                        (O/M) Work

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/Garden?ScreenName=Monitoring of Garden (O/M) Work&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i>  Monitoring of Garden
                                                        (O/M) Work

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i>  Monitoring of Water Supply
                                                        (O/M) Work

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i> Project Tracking & Monitoring
                                                        System -PTMS

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i className="fa-solid fa-caret-right"></i>  Manufacturing & Service ERP

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i className="fa-solid fa-caret-right"></i>  Agriculture Fertilizer
                                                        Manufacturing & Marketing ERP

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none"><i
                                                            className="fa-solid fa-caret-right"></i> Global Kisan market - ICT
                                                        Model
                                                    </a></li>



                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none"><i
                                                            className="fa-solid fa-caret-right"></i> Online Police Gazette

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none">
                                                        <i
                                                            className="fa-solid fa-caret-right"></i> HEALTH CIBIL

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none"
                                                    ><i
                                                        className="fa-solid fa-caret-right"></i> HRMS

                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="javascript:void(0)"
                                                        onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')}
                                                        className="py-1 d-block ahover text-decoration-none"><i
                                                            className="fa-solid fa-caret-right"></i> Nursing Home Registration
                                                        Application

                                                    </a>
                                                </li>
                                            </span>
                                            {/* </span> */}
                                        </ul>

                                    </p>
                                </div>
                                <label for="read-more" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>


                </div>
                <div className="col-12 col-lg-4 py-4">
                    <div className="card charchole text-start">
                        <div className="card-body charchole">
                            <h3 className="white"><b>Clients</b></h3>

                            <ul className="list-unstyled">
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/OurClients?ScreenName=Our Clients&Name=Clients')}
                                        className="py-1 d-block ahover text-decoration-none"><i
                                            className="fa-solid fa-caret-right"></i> Our Clients</a></li>
                                <li>
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ClientReviews?ScreenName=Clients Review&Name=Clients')}
                                        className="py-1 d-block ahover text-decoration-none"><i
                                            className="fa-solid fa-caret-right"></i> Clients Review</a></li>
                            </ul>

                        </div>
                    </div>
                </div>

            </div>



            <Footer />
        </>
    )
}