import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { ApplyNow } from "./ApplyNow"
import { EnjoyInCompany } from "./EnjoyInCompany"
import { Vacancy } from "./Vacancy"

export const CareerPage = () =>{
    return (
        <>
            <Header active={"Careers"}/>
            <HeroSection />
            <EnjoyInCompany/>
            <Vacancy/>
            <ApplyNow/>
            <Footer />
        </>
    )
}