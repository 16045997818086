export const AgricultureInfo = () => {
    return (
        <>

            <div className="row bgdotimg " id="AgricultureInfo">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Agriculture</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Unlocking Agriculture's Potential with Our ITC Model - Empowering Farming Excellence! Welcome to CDAT, where we combine industry expertise with the innovative ITC (Information, Technology, and Communication) model to revolutionize agriculture practices. Our dedicated team of experts is committed to elevating farming operations and empowering farmers with advanced solutions. Embrace the Future of Agriculture with our ITC Model. Empower your farm with advanced technology, data insights, and effective communication for unmatched success.
                    </p>

                </div>
            </div>
        </>
    )
}