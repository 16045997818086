import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { AgricultureAdvatages } from "./AgricultureAdvatages"
import { AgricultureInfo } from "./AgricultureInfo"
import { AgricultureKeyFeatures } from "./AgricultureKeyFeatures"

export const Agriculture = () => {
    return (
        <>
            <Header active="Expertise"/>
            <HeroSection />
            <AgricultureInfo />
            <AgricultureKeyFeatures />
            <AgricultureAdvatages/>
            <BrightFutureTogether />
            <Footer />
        </>
    )
}