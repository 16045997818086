import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



export const Technology = () => {
    const options = {
        // item: 1,
        500: {
            items: 8
        }
    }
    return (
        <>

            <div className="col-12 col-md-12 m-auto py-5 text-center">
                <h3 className="fw-bold colorcharchol">TECHNOLOGY WE USE</h3>

                <div className="zoom-in-out-box2 m-auto">
                    <hr />
                </div>
            </div>



            <div className="col-12 m-auto pt-4">

                <OwlCarousel className='owl-theme'
                    items={3}
                    dots={false}
                    loop={true}
                    autoplay={true}
                    //  autoplayHoverPause={false}
                    autoplayTimeout={1000}
                    //  animateIn="fadeIn"
                    //  animateOut="fadeOut"
                    lazyLoad={false}
                    smartSpeed={10000}
                    margin={40}
                    responsive={options}

                    nav>
                    <div className="item text-center">
                        <h4 className='text-center'>
                            <img src="Assets/Images/tech/android.png" className="img-responsive ds"
                                alt="logo1" />
                            <h6 className='pt-2'>Android</h6>
                        </h4>
                    </div>

                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/apple-logo.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>iOS</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/bootstrap.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Bootstrap</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/coreldraw.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Doreldraw</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/css-3.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>CSS-3</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/dotnet.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>.Net</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/figma.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Figma</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/html.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>HTML</h6>
                        </h4>
                    </div>

                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/illustator-draw.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Illustator</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/jquery.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>JQuery</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/laravel.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Laravel</h6>
                        </h4>
                    </div>
                    <div className="item text-center ">
                        <h4><img src="Assets/Images/tech/xd.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>XD</h6>
                        </h4>
                    </div>


                </OwlCarousel>;

            </div>

            <div className="col-12 m-auto pt-4">

                <OwlCarousel className='owl-theme'
                     items={3}
                    dots={false}
                    loop={true}
                    autoplay={true}
                    //  autoplayHoverPause={false}
                    autoplayTimeout={1000}
                    //  animateIn="fadeIn"
                    //  animateOut="fadeOut"
                    lazyLoad={false}
                    smartSpeed={10000}
                    margin={40}
                    responsive={options}
                    nav>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/mui.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Mui</h6>
                        </h4>
                    </div>

                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/mysql.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>MYSQL</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/node-js.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Node JS</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/photoshop.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Photoshop</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/php.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Php</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/postgresql.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Postgresql</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/react.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>React</h6>
                            {/* namita */}
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/react_native.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>React Native</h6>
                        </h4>
                    </div>

                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/sql-server.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>SQL Server</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/tailwind-css.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Tailwind-css</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/three js.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Three JS</h6>
                        </h4>
                    </div>
                    <div className="item  text-center">
                        <h4><img src="Assets/Images/tech/wordpress.png" className="img-responsive ds"
                            alt="logo1" />
                            <h6 className='pt-2'>Wordpress</h6>
                        </h4>
                    </div>


                </OwlCarousel>;

            </div>
        </>
    )
}