import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { E_CommerceAdvantages } from "./E_CommerceAdvantages"
import { E_CommerceKeyFeatures } from "./E_CommerceKeyFeatures"
import { E_Commerceinfo } from "./E_Commerceinfo"

export const E_Commerce =()=>{
    return(
        <>
        <Header active="Expertise"/>
        <HeroSection/>
        <E_Commerceinfo/>
        <E_CommerceKeyFeatures/>
        <E_CommerceAdvantages/>
        <BrightFutureTogether/>
        <Footer/>
            </>
    )
}