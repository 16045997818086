export const Ourblogs =()=>{
    return(
        <>
               <div className="row  py-5  rowborder" id="blogsinfo">
      <div className="col-12  col-md-4 col-xl-3 mx-auto mt-4 ">
       <div className="card1 card-block  py-3 text-center px-3 charchole">
          <img src="Assets/Images/blogs/cdat-01.png" alt="img not found" className="ds m-auto pe-lg-3"
            style={{width: '40vh', borderRadius: '10%'}}/>
          <h6 className="white pt-lg-4"><b>CDAT ERP</b></h6>
          <p className="white">"Empower your business with ERP systems – the gateway to streamlined
            processes, cost savings, and data-driven decision-making. Explore the evolving ERP landscape and best<span
              id="Edot1">...</span><span id="next1"> practices for success in the digital age."</span></p>
          <p onclick="myFunction()" id="myBtne1" className="silver">Read more</p>
          <div className="py-3 text-center">
            <a href="https://cdat-12.blogspot.com/2023/05/erp-enterprise-resource-planning.html"
              className="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>

          </div>
        </div>
      </div>


      <div className="col-12 col-md-4 col-xl-3 mx-auto mt-4 ">
       <div className="card1 card-block  py-3 text-center px-3 charchole">
            <img src="Assets/Images/blogs/sopi-removebg-preview-01.png" alt="img not found" className="ds m-auto"
                 style={{width: '40vh', borderRadius: '10%'}}/>
            <h6 className="white"><b>SOPI</b></h6>
            <p className="white">"Empower governments with our cutting-edge pension illustration software.
              From accurate calculations to efficient administration, our web-based SOPI system streamlines pension<span id="Edot2">...</span><span
                id="next2"> management, ensuring a secure future for both employees and government agencies."</span>
            </p>
            <p onclick="myFunction2()" id="myBtne2" className="silver">Read more</p>

            <div className="py-3 text-center">
              <a href="https://cdat-12.blogspot.com/2023/05/system-of-pension-illustration.html"
                className="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>

            </div>
          </div>
      </div>



      <div className="col-12 col-md-4 col-xl-3 mx-auto mt-4 ">
       <div className="card1 card-block  py-3 text-center px-3 charchole">
            <img src="Assets/Images/blogs/ASM.png" alt="img not found" className="ds m-auto" 
              style={{width: '40vh', borderRadius: '10%'}}/>
           
            <h6 className="white pt-lg-3"><b>AMS</b></h6>
              <p className="white">"Empower asset management with our user-friendly software. From
                geo-tagging to real-time valuation, our system streamlines asset control at all administrative<span id="Edot3">...</span><span id="next3">
                  levels for
                  more informed decisions."</span>
              </p>
              <p onclick="myFunction3()" id="myBtne3" className="silver">Read more</p>

            <div className="py-3 text-center">
              <a href="https://cdat-12.blogspot.com/2023/05/asset-management-system.html"
                className="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>

            </div>
          </div>
      </div>
    </div>

        <div class="row  py-5  rowborder" id="blogsinfo">
      <div class="col-12  col-md-4 col-xl-3 mx-auto mt-4 ">
        <div class="card1 card-block  py-3 text-center px-3 charchole">
           <img src="Assets/Images/blogs/agreculture-01.png" alt="img not found" class="ds m-auto"
              style={{width: '40vh', borderRadius: '10%'}}/>
           <h6 class="white my-2 mt-4"><b>AGRICULTURE ERP</b></h6>
              <p class="white">"Optimize your agribusiness for success with our Agriculture Fertilizer
                Manufacturing ERP Application. Streamline sales, inventory, production, and administration with CDAT
                Pune's<span id="Edot4">...</span><span id="next4"> web and mobile-based solution."</span>
              </p>
              <p onclick="myFunction4()" id="myBtne4" class="silver">Read more</p>
            <div class="py-3 text-center">
              <a href="https://cdat-12.blogspot.com/2023/09/unlocking-efficiency-in-agribusiness_6.html"
                class="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>
            </div>
          </div>
      </div>


      <div class="col-12 col-md-4 col-xl-3 mx-auto mt-4 ">
      <div class="card1 card-block  py-3 text-center px-3 charchole">
          <img src="Assets/Images/blogs/it_service_blog-01.png" alt="img not found" class="ds mx-auto"
               style={{width: '40vh', borderRadius: '10%'}}/>
          <h6 class="white mt-4"><b>The Profound Advantages of Outsourcing IT Services</b></h6>

              <p class="white"> Outsourcing IT services to CDAT means cost efficiency, access to
                top-notch expertise, and enhanced security. It allows you to focus on core competencies, scale resources,<span id="Edot5">...</span><span
                  id="next5">
                  and gain a competitive edge while ensuring compliance with regulations, providing a strategic imperative
                  for your business.</span></p>
              <p onclick="myFunction5()" id="myBtne5" class="silver">Read more</p>

            <div class="py-3 text-center">
              <a href="https://cdat-12.blogspot.com/2023/10/unleashing-success-profound-advantages.html"
                class="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>

            </div>
          </div>
      </div>



      <div class="col-12 col-md-4 col-xl-3 mx-auto mt-4 ">
        <div class="card1 card-block  py-3 text-center px-3 charchole">
           <img src="Assets/Images/blogs/blogj-01.png" alt="img not found" class="ds mx-auto"
               style={{width: '40vh', borderRadius: '10%'}}/>
         
            <h6 class="white mt-4"><b>CDAT's Professional Product Presentation Approach</b></h6>
    
              <p class="white"> CDAT's product presentations shine with audience-centricity, clarity, and
                compelling  visuals. We weave engaging stories, highlighting features that translate 
                <span id="Edot6">...</span><span id="next6">
                  into real-world benefits. Live demonstrations, addressing questions, competitive analysis, and social proof underscore our commitment to excellence. CDAT is not just software; it's your dedicated partner for success.</span>
              </p>
              <p onclick="myFunction6()" id="myBtne6" class="silver">Read more</p>


            <div class="py-3 text-center">
              <a href="https://cdat-12.blogspot.com/2023/10/elevating-excellence-cdats-professional.html"
                class="shrink-border text-decoration-none" target="_blank">Explore Blogs</a>

            </div>
          </div>
      </div>
    </div>
        </>
    )
}