export const Holi = () => {
    return (
        <>
            <div className="row p-5" id="event_photo_five">

                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center"> Holi</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/holi.jpeg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/holi.jpeg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/holi2.jpeg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/holi2.jpeg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3">
                        <div>
                            <a href="Assets/Images/event/holi3.jpeg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/holi3.jpeg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}