import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Lfms = () => {
    return (
        <>
        <Header active="Products"/>
            <HeroSection />


            {/* ---------------------------- */}

            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Letter and File Management System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Letter & File Management System (LFMS) is a web-based cloud application deployed in municipal
                        corporations, Zilla Parishad offices, and the agriculture department. It serves as a comprehensive
                        solution for managing both incoming and outgoing of letter and file.
                        In Zilla Parishad Office, the system is used by high-ranking executive officers, general
                        administrative officers, junior administrative officers, department heads, and Inward-outward
                        Officers.

                    </p>
                    <p>
                        In municipal corporations, the system caters to commissioners, additional commissioners, city
                        engineers, department heads, and Inward-outward officers.
                        In the agriculture department, it is utilized by agriculture directors, branch heads, department
                        heads, and Inward-outward personnel.

                    </p>
                    <p>
                        Overall, LFMS streamlines the management of letters and files, enhances organization and tracking,
                        and provides valuable insights through metrics and reporting.
                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}


            <div className="row py-5 px-3 " id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Letter and File Online Registration</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Online Inward and Outward</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Global searching </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Status Track and Monitor for letters and files</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Processing Time Metrics</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>QR Code Generation</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=>window.open('http://cdat.co.in/doc/LETTER_AND_FILE_MANAGEMENT_SYSTEM.pdf')}
                        // href="http://new.cdatinfotech.com/doc/LETTER_AND_FILE_MANAGEMENT_SYSTEM.pdf"
                            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}
            <Footer />
        </>
    )
}